import React from 'react'

import VideoCard from './VideoCard'

export default function Cards(props) {



    const getVideoCards = (videos) => {
        console.log("VIDEOS", videos)
        console.log(props.selected)
        return videos.map((video, index) => {
            // inputDateToDate(video.unlockDate)
            console.log(video)
            const selected = (Number.isNaN(Number(props.selected)) || props.selected > 10000) ? (video.videoId == props.selected) : (index == props.selected)
            return <VideoCard key={index} index={index} video={video} selected={selected}></VideoCard>
        })
    }
    return (
        // <!-- Cards -->
        <section className="bg-gray pt-4 py-md-4">
            <div className="container">
                <div className="h4 my-3">VÍDEOS RECOMENDADOS</div>
                <div className="row">
                    {getVideoCards(props.videos)}
                </div>
            </div>
        </section>
    )
}
