import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

export default function SideBar(props) {
    const location = useLocation()
    const [base, setBase] = useState("")
    // const [selected, setSelected] = useState(null)
    const options = [
        // {
        //     label: "Link Trees",
        //     link: "linktree"
        // },
        {
            label: "Short Links",
            link: "shortlinks"
        },
        {
            label: "Video Contents",
            link: "videocontents"
        },
        {
            label: "Link Trees",
            link: "linktree"
        },
        {
            label: "Captures",
            link: "capture"
        },
        {
            label: "Uploads",
            link: "uploads"
        }
    ]
    useEffect(() => {
        console.log(location.pathname.split('/'))
        const paths = location.pathname.split('/')
        setBase(paths.filter(item => item !== "")[0])
    }, [location])
    useEffect(() => {
        console.log(base)
    }, [base])
    return (
        <div className={`${props.className}  border-top `}>
            <ul className="list-unstyled">
                {options.map((value, index) => {
                    console.log(base)
                    return <li className="py-2 px-5 border-bottom" key={index}>
                        <Link className="text-dark" to={`/${base}/${value.link}`}>{value.label}</Link>
                    </li>
                })}
            </ul>

        </div>
    )
}

