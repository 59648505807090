import React, { useState } from 'react'
import { Card, Button } from 'react-bootstrap'
import { TrashIcon, PencilIcon } from '@primer/octicons-react'
import { removeLinktree } from './LinkTreeController'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

export default function LinkTreeItem(props) {
    const auth = useSelector(state => state.auth);

    const removeItem = (id) => {
        console.log("Removing Item")
        removeLinktree(id, auth.accessToken).then(data => {
            console.log(data)
            if (data.deletedCount > 0)
                props.itemRemoved(id)
        }).catch(error => {
            console.log(error)
        })
    }

    // const showLinkTrees= (show) => {
    //     if (show) {
    //         return <div className="pt-4">{props.item.videos.map((video, index) => <VideoItem key={video._id} video={video} />)}</div>
    //     }
    // }

    return (
        <li>
            <Card className="p-2 mb-2 d-flex">
                <div className="d-flex flex-row align-items-center justify-content-between mb-3">
                    <div>
                        <Card.Title>Endpoint: {props.item.endpoint}</Card.Title>
                        <div>
                            <strong>Description:</strong> {props.item.pageDescription}
                        </div>
                    </div>
                    <div className="d-flex">
                        <Button className="mr-3">
                            <Link className="btn btn-primary" to={{ pathname: `/dashboard/linktree/edit`, content: props.item }} ><PencilIcon size={24} /></Link>
                        </Button>
                        <Button variant="danger" className="" onClick={() => { console.log(props.item._id); removeItem(props.item._id) }}>
                            <TrashIcon size={24}  ></TrashIcon>
                        </Button>
                    </div>
                </div>
                {/* <Button onClick={() => setShow(prev => !prev)}>{show ? "Hide videos" : "Show videos"}</Button>
                <div>

                    {showVideos(show)}

                </div> */}


            </Card>
            {/* <Card>

            </Card> */}

        </li >
    )
}
