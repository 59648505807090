import React, { useEffect, useState } from 'react'
import Banner from '../Banner';
import EmailCapture from '../EmailCapture';
import Footer from '../Footer';
import Navbar from '../Navbar';
import GameBoard from './components/GameBoard';
import GameOver from './components/GameOver'
import game from './game/game'
import './MemoryGame.css'
export default function MemoryGame() {

    const [gameOver, setGameOver] = useState(false);
    const [cards, setCards] = useState([])

    useEffect(() => {
        setCards(game.createCardsFromTechs())
    }, [])

    function restart() {
        game.clearCards()
        setCards(game.createCardsFromTechs())
        setGameOver(false)
    }

    function handleFlip(card) {
        game.flipCard(card.id, () => {
            // GameOverCallback
            setTimeout(() => {
                setGameOver(true)
            }, 600)

        }, () => {
            //NoMatchCallback
            setCards([...game.cards])
        })
        setCards([...game.cards])
    }

    return (
        <div>
            <Navbar></Navbar>
            <div className="memory-game">
                <GameBoard handleFlip={handleFlip} cards={cards}></GameBoard>
                <GameOver show={gameOver} handleRestart={restart}></GameOver>

            </div>
            <EmailCapture></EmailCapture>
            <Banner src="memory"></Banner>
            <Footer></Footer>
        </div>
    )
}
