function crudController(module) {
    return {
        getAll: () => {
            return new Promise((resolve, reject) => {
                var headers = new Headers();
                headers.append("Content-Type", "application/json");
                var config = {
                    method: 'POST',
                    headers: headers,
                };
                fetch(`/${module}/all`, config).then(async response => {
                    if (response.status >= 400) {
                        reject("Unauthorized")
                    }
                    console.log(response)
                    return response.json()
                }
                ).then(json => {
                    resolve(json)
                }).catch(error => {
                    console.log(error)
                    reject(error)
                })

            })
        },

        getByKeyValue: (key, value) => {
            return new Promise((resolve, reject) => {
                var headers = new Headers();
                headers.append("Content-Type", "application/json");
                var config = {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify({ key, value })
                };
                fetch(`/${module}/get`, config).then(async response => {
                    if (response.status >= 400) {
                        reject("Unauthorized")
                        return
                    }
                    return response.json()
                }
                ).then(json => {
                    console.log("JSON", json)
                    resolve(json)
                }).catch(error => {
                    console.log(error)
                    reject(error)
                })

            })
        },

        add: (accessToken, object) => {

            return new Promise((resolve, reject) => {

                var headers = new Headers();
                headers.append("Content-Type", "application/json");
                headers.append("x-auth-token", accessToken);

                const body = JSON.stringify(object)

                var config = {
                    method: 'POST',
                    headers: headers,
                    body
                };
                fetch('/module/add', config).then(async response => {
                    return response.json()
                }
                ).then(json => {
                    resolve(json)
                }).catch(error => {
                    console.log(error)
                    reject(error)
                })

            })
        },

        update: (accessToken, id, object) => {
            return new Promise((resolve, reject) => {

                var headers = new Headers();
                headers.append("Content-Type", "application/json");
                headers.append("x-auth-token", accessToken);

                const body = JSON.stringify({ _id: id, ...object })

                var config = {
                    method: 'POST',
                    headers: headers,
                    body
                };
                fetch(`/${module}/update`, config).then(async response => {
                    return response.json()
                }
                ).then(json => {
                    resolve(json)
                }).catch(error => {
                    console.log(error)
                    reject(error)
                })

            })
        },

        remove: (_id, accessToken) => {
            return new Promise((resolve, reject) => {
                var headers = new Headers();
                headers.append("Content-Type", "application/json");
                headers.append("x-auth-token", accessToken);

                const body = JSON.stringify({ _id })

                var config = {
                    method: 'POST',
                    headers: headers,
                    body
                };
                fetch(`/${module}/remove `, config).then(async response => {
                    if (response.status > 400) {
                        throw response
                    }
                    return response.json()
                }
                ).then(json => {
                    resolve(json)
                }).catch(error => {
                    console.log(error)
                    reject(error)
                })
            })
        }
    }
}

export default crudController 