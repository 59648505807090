


const orangeGradient = 'linear-gradient(205.06deg, #FFDF00 0%, #FE4D1B 48.15%, #E70154 100%);'
const blueGradient = 'linear-gradient(25.06deg, #1F95FD 0%, #4FE8E4 100%);'
const purpleGradient = 'linear-gradient(205.06deg, #C500E8 0%, #573592 100%);'
const darkBlue = 'linear-gradient(204.5deg, #29678B 0%, #0E1C34 100%);'
const orangeShadow = 'rgba(252,71,31,0.8)'
const blueShadow = ' rgba(79,232,228,0.5)'
const purpleShadow = 'rgba(168, 13, 210, 0.1)'


export const mainFont = 'Eurostile'


const getColor = (color) => {
    switch (color) {
        case ' ':
        case null:
        case false:
        case undefined:
        case 'orange':
            return { gradient: orangeGradient, fontColor: "white", shadow: orangeShadow }
        case 'blue':
            return { gradient: blueGradient, fontColor: "#0E1C34", shadow: blueShadow }
        case 'dark':
            return { gradient: darkBlue, fontColor: "#0E1C34", shadow: blueShadow }
        case 'purple':
            return { gradient: purpleGradient, fontColor: "white", shadow: purpleShadow }

        default:
            return { gradient: color, fontColor: "white", shadow: orangeShadow }
    }
}

export const getColorGradient = (color) => {
    return getColor(color).gradient
}
export const getFontColor = (color) => {
    return getColor(color).fontColor
}

export const getShadowColor = (color) => {
    return getColor(color).shadow
}