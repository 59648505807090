import React, { useContext, useRef, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button'
import { EnglishTrainerContext } from './EnglishTrainerProvider';
import Transcriber from './Transcriber';
import './english.css'

var SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
var SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList;
// var SpeechRecognitionEvent = window.SpeechRecognitionEvent || window.webkitSpeechRecognitionEvent;

const synth = window.speechSynthesis;
let utterThis = null


export default function Talker(props) {

    const { selectedVoice, speechRate } = useContext(EnglishTrainerContext)

    const [listenedSentence, setListenedSentence] = useState(" ")

    function speak() {
        if (synth.speaking) {
            synth.cancel()
            console.error('speechSynthesis.speaking');
            // return;
        }

        utterThis = new SpeechSynthesisUtterance(props.sentence);
        utterThis.voice = selectedVoice;
        utterThis.rate = speechRate;

        utterThis.onend = function (event) {
            console.log('SpeechSynthesisUtterance.onend');
        }
        utterThis.onerror = function (event) {
            console.error('SpeechSynthesisUtterance.onerror');
        }
        // utterThis.pitch = pitch.value;
        // utterThis.rate = rate.value;
        synth.speak(utterThis);
    }

    function listen(phrase) {
        setListenedSentence("Listening...")

        // setActualSentence(props.sentence)

        var recognition = new SpeechRecognition();
        var speechRecognitionList = new SpeechGrammarList();
        if (props.sentence) {
            var grammar = '#JSGF V1.0; grammar phrase; public <phrase> = ' + props.sentence + ';';
            speechRecognitionList.addFromString(grammar, 1);
        }
        recognition.grammars = speechRecognitionList;
        recognition.lang = 'en-US';
        recognition.interimResults = false;
        recognition.maxAlternatives = 1;

        recognition.start();
        record()
        recognition.onresult = function (event) {
            var speechResult = event.results[0][0].transcript.toLowerCase();
            console.log("Speech Result", speechResult)
            setListenedSentence(speechResult)
        }

        recognition.onspeechend = function () {


            recognition.stop();
        }

        recognition.onerror = function (event) {

        }

        recognition.onaudiostart = function (event) {
            //Fired when the user agent has started to capture audio.
            console.log('SpeechRecognition.onaudiostart');
        }

        recognition.onaudioend = function (event) {
            //Fired when the user agent has finished capturing audio.
            console.log('SpeechRecognition.onaudioend');
        }

        recognition.onend = function (event) {
            //Fired when the speech recognition service has disconnected.
            setShouldStop(true)
            // setListenedSentence("Did not complete")

            setListenedSentence(prev => {
                if (prev === "Listening...") {
                    return "Algo deu errado, verifique seu microfone"
                } else {
                    return prev
                }
            })

            console.log('SpeechRecognition.onend');
        }

        recognition.onnomatch = function (event) {
            //Fired when the speech recognition service returns a final result with no significant recognition. This may involve some degree of recognition, which doesn't meet or exceed the confidence threshold.
            console.log('SpeechRecognition.onnomatch');
        }

        recognition.onsoundstart = function (event) {
            //Fired when any sound — recognisable speech or not — has been detected.
            console.log('SpeechRecognition.onsoundstart');
        }

        recognition.onsoundend = function (event) {
            //Fired when any sound — recognisable speech or not — has stopped being detected.
            console.log('SpeechRecognition.onsoundend');
        }

        recognition.onspeechstart = function (event) {
            //Fired when sound that is recognised by the speech recognition service as speech has been detected.
            console.log('SpeechRecognition.onspeechstart');
        }
        recognition.onstart = function (event) {
            //Fired when the speech recognition service has begun listening to incoming audio with intent to recognize grammars associated with the current SpeechRecognition.
            console.log('SpeechRecognition.onstart');
        }
    }
    /////---------------
    const player = useRef()

    const [stopped, setStopped] = useState(true);
    const [shouldStop, setShouldStop] = useState(false);

    const [mediaRecorder, setMediaRecorder] = useState(null)


    const handleSuccess = function (stream) {
        console.log("Handleling")
        const options = { mimeType: 'audio/webm' };
        player.current.src = null

        if (mediaRecorder === null)
            setMediaRecorder(new MediaRecorder(stream, options));
        console.log(mediaRecorder)
        // setStopped(false)

    };

    useEffect(() => {
        if (!mediaRecorder)
            return
        const recordedChunks = [];


        mediaRecorder.start();
        setStopped(false)

        mediaRecorder.ondataavailable = function (e) {
            console.log(e.data)
            if (e.data.size > 0) {
                recordedChunks.push(e.data);
            }
        };

        mediaRecorder.onstop = function () {
            console.log("Stop")
            player.current.src = URL.createObjectURL(new Blob(recordedChunks));
            mediaRecorder.ondataavailable = null
            mediaRecorder.onstop = null
            setMediaRecorder(null)
            // downloadLink.download = 'acetest.wav';
        };
    }, [mediaRecorder])

    useEffect(() => {
        if (shouldStop === true && stopped === false) {
            mediaRecorder.stop();
            setStopped(true)
        }
    }, [stopped, shouldStop])

    function record() {
        if (stopped) {
            setShouldStop(false)
            navigator.mediaDevices.getUserMedia({ audio: true, video: false })
                .then(handleSuccess);
        }
    }
    //---------
    return (
        <div className="card my-3">
            <h5 className="card-header">{props.sentence}</h5>
            <div className="card-body">
                <Transcriber listenedSentence={listenedSentence} actualSentence={props.sentence}></Transcriber>
                <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                    <audio className="my-2 " ref={player} id="player" controls></audio>
                    <div className="d-flex flex-column flex-md-row flex-grow-1 w-100 justify-content-md-end">
                        <Button variant="" className="mx-md-2 my-1 my-md-0  english-btn" onClick={speak}>Listen</Button>
                        <Button variant="" className=" english-btn " onClick={listen}>Speak</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
