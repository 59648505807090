import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getLinkTrees } from './LinkTreeController'
import LinkTreeList from './LinkTreeList'

export default function LinkTrees() {

    const [linkTrees, setLinkTrees] = useState([])
    const [unauthorized, setUnauthorized] = useState(false)

    useEffect(() => {
        console.log("VIDEO CONTENT")
        getLinkTrees().then(contents => {
            console.log("contents")

            setLinkTrees(contents)
            console.log(contents)
        }).catch(error => {
            // setUnauthorized(true)
            console.log(error)
        })
    }, [])

    function itemRemoved(id) {
        setLinkTrees(prev => prev.filter(item => item._id != id))
    }

    function getContents() {
        if (unauthorized)
            return <div>Acesso Negado</div>
        return <div>

            <header className="d-flex justify-content-end mb-2">
                <Link className="btn btn-primary" to={`/dashboard/linktrees/add`}>+</Link>
            </header>
            {/* <VideoContentsList itemRemoved={} shortLinks={videoContents}></VideoContentsList> */}
            <LinkTreeList itemRemoved={itemRemoved} linkTrees={linkTrees}></LinkTreeList>
        </div>

    }
    return (
        <div>
            {getContents()}

        </div>
    )
}
