// import Stories_Vertical_Orange_Thinking from './Stories_Vertical_Orange_Thinking.png'
// import Stories_Vertical_Detailed_Orange_Thinking from './Stories_Vertical_Detailed_Orange_Thinking.png'
// import Stories_Vertical_Detailed_Blue_Smiling from './Stories_Vertical_Detailed_Blue_Smiling.png'
// import Banner_Vertical_300x600_Detailed_Orange_Thinking from './Banner_Vertical_300x600_Detailed_Orange_Thinking.png'
// import Stories_Vertical_Blue_Smiling from './Stories_Vertical_Blue_Smiling.png'
// import Banner_Vertical_160x600_Blue_Thinking from './Banner_Vertical_160x600_Blue_Thinking.png'
// import Banner_Vertical_300x600_Blue_Smiling from './Banner_Vertical_300x600_Blue_Smiling.png'
// import Banner_Square_336x280_Orange_Thinking from './Banner_Square_336x280_Orange_Thinking.png'
// import Banner_Square_500x500_Blue_Smiling from './Banner_Square_500x500_Blue_Smiling.png'
// import Banner_Square_500x500_Orange_Chocked from './Banner_Square_500x500_Orange_Chocked.png'
// import Banner_Square_300x250_Blue from './Banner_Square_300x250_Blue.png'
// import Banner_Square_300x250_Orange_Thinking from './Banner_Square_300x250_Orange_Thinking.png'
// import Banner_Square_336x280_Blue_Smiling from './Banner_Square_336x280_Blue_Smiling.png'
// import Banner_Square_336x280_Blue from './Banner_Square_336x280_Blue.png'
// import Banner_Landscape_580x278_Blue_Smiling from './Banner_Landscape_580x278_Blue_Smiling.png'
// import Banner_Landscape_580x278_Orange_Thinking from './Banner_Landscape_580x278_Orange_Thinking.png'
// import Banner_Landscape_580x176_Blue from './Banner_Landscape_580x176_Blue.png'
// import Banner_Landscape_580x176_Orange from './Banner_Landscape_580x176_Orange.png'
// import Banner_Horizontal_1160x130_Blue_Smiling from './Banner_Horizontal_1160x130_Blue_Smiling.png'
// import Banner_Horizontal_1160x130_Organge_Smiling from './Banner_Horizontal_1160x130_Organge_Smiling.png'
// import Banner_Horizontal_970x90_Blue_Smiling from './Banner_Horizontal_970x90_Blue_Smiling.png'
// import Banner_Horizontal_728x90_Blue from './Banner_Horizontal_728x90_Blue.png'
// import Banner_Horizontal_728x90_Orange_Smiling from './Banner_Horizontal_728x90_Orange_Smiling.png'

import Banner_300x600 from './Banner_300x600.jpg'
import Banner_500x500 from './Banner_500x500.jpg'
import Banner_580x278 from './Banner_580x278.jpg'
import Banner_728x90 from './Banner_728x90.jpg'
import Banner_1160x130 from './Banner_1160x130.jpg'



export default {
    Banner_300x600,
    Banner_500x500,
    Banner_580x278,
    Banner_728x90,
    Banner_1160x130

}


// export default {
//     Stories_Vertical_Orange_Thinking,
//     Stories_Vertical_Detailed_Orange_Thinking,
//     Stories_Vertical_Detailed_Blue_Smiling,
//     Banner_Vertical_300x600_Detailed_Orange_Thinking,
//     Stories_Vertical_Blue_Smiling,
//     Banner_Vertical_160x600_Blue_Thinking,
//     Banner_Vertical_300x600_Blue_Smiling,
//     Banner_Square_336x280_Orange_Thinking,
//     Banner_Square_500x500_Blue_Smiling,
//     Banner_Square_500x500_Orange_Chocked,
//     Banner_Square_300x250_Blue,
//     Banner_Square_300x250_Orange_Thinking,
//     Banner_Square_336x280_Blue_Smiling,
//     Banner_Square_336x280_Blue,
//     Banner_Landscape_580x278_Blue_Smiling,
//     Banner_Landscape_580x278_Orange_Thinking,
//     Banner_Landscape_580x176_Blue,
//     Banner_Landscape_580x176_Orange,
//     Banner_Horizontal_1160x130_Blue_Smiling,
//     Banner_Horizontal_1160x130_Organge_Smiling,
//     Banner_Horizontal_970x90_Blue_Smiling,
//     Banner_Horizontal_728x90_Blue,
//     Banner_Horizontal_728x90_Orange_Smiling
// }