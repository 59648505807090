import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import crudController from '../Controller/crudController';
import ItemList from './ItemList';

export default function CrudList(props) {
    // const auth = useSelector(state => state.auth);
    const controller = crudController(props.module)
    const [allItems, setAllItems] = useState([])
    const [unauthorized, setUnauthorized] = useState(false)

    useEffect(() => {
        controller.getAll().then(contents => {
            setAllItems(contents)
        }).catch(error => {
            // setUnauthorized(true)
            console.log(error)
        })
    }, [])

    function itemRemoved(id) {
        setAllItems(prev => prev.filter(item => item._id != id))
    }

    function getContents() {
        if (unauthorized)
            return <div>Acesso Negado</div>
        return <div>
            <header className="d-flex justify-content-end mb-2">
                <Link className="btn btn-primary" to={`/dashboard/${props.module}/add`}>+</Link>
            </header>
            <ItemList itemRemoved={itemRemoved} items={allItems} {...props}></ItemList>
        </div>
    }

    return (
        <div>
            {getContents()}
        </div>
    )
}
