import React, { useState, useEffect } from 'react'
import { Button, Alert } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
// import { addVideoContent, updateVideoContent } from '../VideoContentController'
// import VideoCard from './VideoCard'
import ButtonCard from './ButtonCard'
import { useSelector } from 'react-redux'
import { addLinkTree, updateLinkTree } from '../LinkTreeController'

// export default function LinkTreesAdd() {
//     return <></>
// }

export default function LinkTreesAdd(props) {
    const history = useHistory()
    const auth = useSelector(state => state.auth);

    const [pageBgColor, setPageBgColor] = useState("#ffffff")
    const [pageTitleColor, setPageTitleColor] = useState("#333333")
    const [pageDescriptionColor, setPageDescriptionColor] = useState("#333333")
    const [endpoint, setEndpoint] = useState("")

    const [pageTitle, setPageTitle] = useState("")
    const [pageDescription, setPageDescription] = useState("")
    const [pageImageLink, setPageImageLink] = useState("")

    //default Btn Values

    const [btnBgColorDefault, setBtnBgColorDefault] = useState("#ffffff")
    const [btnBorderColorDefault, setBtnBorderColorDefault] = useState("#000000")

    const [btnTitleColorDefault, setBtnTitleColorDefault] = useState("#333333")
    const [btnDescriptionColorDefault, setBtnDescriptionColorDefault] = useState("#333333")

    const [btnTitleDefault, setBtnTitleDefault] = useState("")

    const [btnDescriptionDefault, setBtnDescriptionDefault] = useState("")
    const [btnImageLinkDefault, setBtnImageLinkDefault] = useState("")

    const [buttons, setButtons] = useState([])

    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    function createNewEmptyButton() {
        return {
            btnBgColor: btnBgColorDefault,
            btnBorderColor: btnBorderColorDefault,
            btnTitleColor: btnTitleColorDefault,
            btnDescriptionColor: btnDescriptionColorDefault,
            btnTitle: btnTitleDefault,
            btnDestination: "",
            btnDescription: btnDescriptionDefault,
            btnImageLink: btnImageLinkDefault
        }
    }
    function removeButton(index) {
        setButtons(buttons.filter((button, idx) => index !== idx))
    }



    useEffect(() => {
        console.log(props)
        if (props.location && props.location.content) {
            const linkTree = props.location.content
            console.log(linkTree)


            setPageBgColor(linkTree.pageBgColor)
            setPageTitleColor(linkTree.pageTitleColor)
            setPageDescriptionColor(linkTree.pageDescriptionColor)
            setEndpoint(linkTree.endpoint)
            setPageTitle(linkTree.pageTitle)
            setPageDescription(linkTree.pageDescription)
            setPageImageLink(linkTree.pageImageLink)
            setButtons(linkTree.buttons || [])

            setBtnBgColorDefault(linkTree.btnBgColorDefault || btnBgColorDefault)
            setBtnBorderColorDefault(linkTree.btnBorderColorDefault || btnBorderColorDefault)
            setBtnTitleColorDefault(linkTree.btnTitleColorDefault || btnTitleColorDefault)
            setBtnDescriptionColorDefault(linkTree.btnDescriptionColorDefault || btnDescriptionColorDefault)
            setBtnTitleDefault(linkTree.btnTitleDefault || btnTitleDefault)
            setBtnDescriptionDefault(linkTree.btnDescriptionDefault || btnDescriptionDefault)
            setBtnImageLinkDefault(linkTree.btnImageLinkDefault || btnImageLinkDefault)



        }
    }, [props])
    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(false)
            }, 3000);
        }
    }, [error])

    function cancel() {
        history.goBack()
    }

    function save() {
        let error = false
        if (endpoint.replace(/\s+/g, '') === "" || pageTitle.replace(/\s+/g, '') === "" || pageDescription.replace(/\s+/g, '') === "" || buttons.length === 0) {
            error = true;
            setError("Verifique o Endpoint e a Description")
            return
        }
        buttons.every((button, index) => {
            if (button.btnTitle.replace(/\s+/g, '') === "" ||
                // button.title.replace(/\s+/g, '') === "" ||
                button.btnDestination.replace(/\s+/g, '') === "") {
                setError(`Verifique os dados no botão ${index}`)
                error = true;

                return false
            }
            return true
        })

        function handlePromise(promise) {
            promise.then(response => {
                console.log(response)
                if (response.status > 400) {
                    setError("Não foi Possivel salvar")
                } else {
                    if (response.nModified || response._id) {
                        setSuccess("Content Group salvo com sucesso")
                        setTimeout(() => {
                            history.goBack()
                        }, 1000);
                    } else {
                        setError("Ocorreu um erro na hora de salvar")
                    }
                }
            }).catch(error => {
                console.log(error)
                setError("Não foi Possivel salvar")
            })
        }
        if (!error) {

            const linkTree = {
                endpoint,
                pageBgColor,
                pageTitleColor,
                pageDescriptionColor,
                pageTitle,
                pageDescription,
                pageImageLink,
                btnBgColorDefault,
                btnBorderColorDefault,
                btnTitleColorDefault,
                btnDescriptionColorDefault,
                btnTitleDefault,
                btnDescriptionDefault,
                btnImageLinkDefault,
                buttons
            }

            if (props.location && props.location.content) {
                handlePromise(updateLinkTree(auth.accessToken, props.location.content._id, linkTree))
            } else {
                handlePromise(addLinkTree(auth.accessToken, linkTree))
            }
        }

    }





    const updateButtonData = (index, key, value) => {
        console.log("UPDATE BUTTON DATA", index, key, value)
        setButtons(prev => {
            prev[index][key] = value
            return prev
        })
    }

    useEffect(() => {
        console.log(buttons)
    }, [buttons])



    const ButtonCards = ({ buttonsData, updateButtonData, removeButton }) => {


        console.log("Buttons", buttons)
        return buttonsData.map((item, index) => <ButtonCard removeButton={removeButton} key={index} buttonValue={item} index={index} updateVideoData={updateButtonData}></ButtonCard>)

    }
    return (
        <div>
            <header className="d-flex justify-content-end">
                <Button onClick={cancel} variant="danger" className="mr-2  ">
                    Cancel
                </Button>
                <Button onClick={save}>
                    Save
                </Button>
            </header>



            <form className="d-flex  mt-4 w-100 ">
                <div className="d-flex flex-column w-50 p-1">
                    <h5>Page</h5>
                    <input onChange={element => { setEndpoint(element.target.value) }} className="mb-3" name="pageTitle" id="endpoint" placeholder="Endpoint" value={endpoint}></input>

                    <input onChange={element => { setPageTitle(element.target.value) }} className="mb-3" name="pageTitle" id="pageTitle" placeholder="Title" value={pageTitle}></input>

                    <input onChange={element => { setPageDescription(element.target.value) }} className="mb-3" name="pageDescription" id="pageDescription" placeholder="Description" value={pageDescription}></input>

                    <input onChange={element => { setPageImageLink(element.target.value) }} className="mb-3" name="pageImageLink" id="pageImageLink" placeholder="Page Image Link" value={pageImageLink}></input>

                    <div className="d-flex justify-content-between">
                        <label for="pageBgColor">Page Bg Color</label>
                        <input type="color" onChange={element => { setPageBgColor(element.target.value) }} className="mb-3" name="pageBgColor" id="pageBgColor" placeholder="Background Color" value={pageBgColor}></input>
                    </div>
                    <div className="d-flex justify-content-between">
                        <label for="pageTitleColor">Page Title Color</label>
                        <input type="color" onChange={element => { setPageTitleColor(element.target.value) }} className="mb-3" name="pageTitleColor" id="pageTitleColor" placeholder="Title Color" value={pageTitleColor}></input>
                    </div>
                    <div className="d-flex justify-content-between">
                        <label for="pageDescriptionColor">Page Description Color</label>
                        <input type="color" onChange={element => { setPageDescriptionColor(element.target.value) }} className="mb-3" name="pageDescriptionColor" id="pageDescriptionColor" placeholder="Text Color" value={pageDescriptionColor}></input>
                    </div>
                </div>
                <div className="d-flex flex-column w-50 p-1">
                    <h5>Button Defaults</h5>
                    <input onChange={element => { setBtnTitleDefault(element.target.value) }} className="mb-3" name="btnTitleDefault" id="btnTitleDefault" placeholder="Title" value={btnTitleDefault}></input>

                    <input onChange={element => { setBtnDescriptionDefault(element.target.value) }} className="mb-3" name="btnDescriptionDefault" id="btnDescriptionDefault" placeholder="Description" value={btnDescriptionDefault}></input>

                    <input onChange={element => { setBtnImageLinkDefault(element.target.value) }} className="mb-3" name="btnImageLinkDefault" id="btnImageLinkDefault" placeholder="Image Link" value={btnImageLinkDefault}></input>
                    <div className="d-flex justify-content-between">
                        <label for="btnBGColorDefault">Btn Bg Color Default</label>
                        <input type="color" onChange={element => { setBtnBgColorDefault(element.target.value) }} className="mb-3" name="btnBgColorDefault" id="btnBgColorDefault" placeholder="Button Bg Color" value={btnBgColorDefault}></input>
                    </div>
                    <div className="d-flex justify-content-between">
                        <label for="btnBorderColorDefault">Btn Border Color Default</label>
                        <input type="color" onChange={element => { setBtnBorderColorDefault(element.target.value) }} className="mb-3" name="btnBorderColorDefault" id="btnBorderColorDefault" placeholder="Button Border Color" value={btnBorderColorDefault}></input>
                    </div>
                    <div className="d-flex justify-content-between">
                        <label for="btnTitleColorDefault">Btn Title Color Default</label>
                        <input type="color" onChange={element => { setBtnTitleColorDefault(element.target.value) }} className="mb-3" name="btnTitleColorDefault" id="btnTitleColorDefault" placeholder="Title Color" value={btnTitleColorDefault}></input>
                    </div>
                    <div className="d-flex justify-content-between">
                        <label for="btnDescriptionColorDefault">Btn Description Color Default</label>
                        <input type="color" onChange={element => { setBtnDescriptionColorDefault(element.target.value) }} className="mb-3" name="btnDescriptionColorDefault" id="btnDescriptionColorDefault" placeholder="Text Color" value={btnDescriptionColorDefault}></input>
                    </div>
                </div>
            </form>

            <Button className="mr-2" onClick={() => {
                setButtons(prev => {
                    const copy = [createNewEmptyButton(), ...prev]
                    return copy;
                })
            }}>+ First</Button>
            <Button onClick={() => {
                setButtons(prev => {
                    const copy = [...prev, createNewEmptyButton()]
                    return copy;
                })
            }}>+ Last</Button>
            <div className="mt-4 mb-5">
                <ButtonCards removeButton={removeButton} updateButtonData={updateButtonData} buttonsData={buttons}></ButtonCards>
            </div>
            {/* <VideoCards removeVideo={removeVideo} updateVideoData={updateVideoData} videosData={videos}></VideoCards> */}
            <Alert show={error} variant="danger">{error}</Alert>
            <Alert show={success} variant="success">{success}</Alert>
        </div >
    )
}
