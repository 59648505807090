import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { LockIcon } from '@primer/octicons-react'
import { inputDateToDate } from '../common/DateHelper'
export default function VideoCard(props) {
    const location = useLocation()
    const args = location.pathname.split("/")
    const pathName = `/${args[1]}/${args[2]}`

    const [imageDataURL, setImageDataURL] = useState("")
    // const [loaded, setLoaded] = useState(false)
    // useEffect(() => {
    // }, [imageDataURL])

    useEffect(() => {
        if (props.video.thumbnailUrl) {
            setImageDataURL(props.video.thumbnailUrl)
        } else {
            if (props.video.platform == "youtube") {
                setImageDataURL(`https://img.youtube.com/vi/${props.video.videoId}/maxresdefault.jpg`)
            }
        }
    }, [props.video])

    function isSelected() {
        console.log(props.selected)
        if (props.selected) {
            return <div key="selected"
                className="top-0 position-absolute w-100 h-100 bg-white semi-tranpatent  d-flex justify-content-center align-items-center">
            </div>
        }
    }
    function locked(locked) {
        if (locked) {
            console.log(locked)
            return <div
                className="top-0 position-absolute w-100 h-100 bg-dark  semi-tranpatent  d-flex justify-content-center align-items-center">
                <LockIcon className="bg-light rounded" size={64} />
            </div >
        }
    }

    const isVideoLocked = (video) => {
        const now = new Date(Date.now())
        return inputDateToDate(video.unlockDate) > now

    }

    const Card = (props) => {
        return (<div className="card h-100">
            {isSelected()}

            <img className="card-img-top"
                src={imageDataURL}
                alt="Card cap" />


            <div className="card-body">{props.video.title} </div>
            {locked(props.locked)}
        </div>)
    }

    return (
        <div key={props.index} className="col-lg-3 col-md-6 col-sm-12 mb-4 mb-md-5">
            {(() => { return !isVideoLocked(props.video) ? <Link to={`${pathName}/${props.video.videoId}`} children={[<Card video={props.video}></Card>]} /> : <Card video={props.video} locked={true}></Card> })()}
        </div>
    )
}
