import React from 'react'
import VideoContentItem from './VideoContentItem';

export default function VideoContentsList(props) {

    return (
        <ul className="list-unstyled">{props.videoContents.map((item, index) =>
            // <VideoContentItem itemRemoved={} key={index} item={item} />
            <VideoContentItem itemRemoved={props.itemRemoved} key={index} item={item} />

        )}</ul>
    )
}
