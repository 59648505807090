import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './PictureFilter.css'
import ImageFilter from './components/ImageFilter';
import InputFile from './components/InputFile';
import Banner from '../Banner';
import EmailCapture from '../EmailCapture';
import Footer from '../Footer';
import Navbar from '../Navbar';
export default function PictureFilter() {

    const [fileSrc, setFileSrc] = useState("")

    const handleSelectFile = (event) => {
        const file = event.target.files[0]
        setFileSrc(URL.createObjectURL(file))
    }
    return (
        <div>
            <Navbar></Navbar>
            <div className="container p-2">
                <ImageFilter fileSrc={fileSrc}></ImageFilter>
                <InputFile handleSelectFile={handleSelectFile} />

            </div>
            <EmailCapture></EmailCapture>
            <Banner src="memory"></Banner>
            <Footer></Footer>
        </div>
    )
}
