import React from 'react'

export default function Navbar() {
    return (
        // <!-- Navbar -->
        <section className="bg-dark-blue">
            <nav className="container navbar navbar-expand-md navbar-dark py-2">
                {/* <!-- Nav-logo --> */}
                <a className="navbar-brand" href="https://www.programadorbr.com"><img
                    src="/assets/content/images/logo_progbr_blue.png" alt="logo" height="50px" target="_blank" /></a>
                {/* <!-- Toogler-icon --> */}
                {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar"
                    aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button> */}
                {/* <!-- Nav-links --> */}
                <div className="collapse navbar-collapse justify-content-end" id="navbar">
                    <ul className="navbar-nav align-items-center">
                        <li className="nav-item mr-2 text-sm"><a href="https://www.youtube.com/programadorbr" className="nav-link"
                            target="_blank" rel="noopener noreferrer">Canal
                                ProgBR</a></li>
                        <li className="nav-item mr-2 text-sm"><a href="https://www.youtube.com/programadorbrtutoriais"
                            className="nav-link" target="_blank" rel="noopener noreferrer">Canal
                                Tutoriais</a></li>
                        <li className="nav-item mr-2 text-sm"><a href="https://www.instagram.com/progbr/" className="nav-link"
                            target="_blank" rel="noopener noreferrer">Instagram</a>
                        </li>

                    </ul>
                </div>
                <a href="https://www.programadorbr.com" target="_blank" rel="noopener noreferrer">
                    <li className="btn btn-orange my-2 text-sm">CURSO DEV WEB</li>
                </a>
            </nav>
        </section>
    )
}
