import React, { useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { authenticateWithToken } from './Login/authController'
import tokenManager from './Login/tokenManager';
import authAction from '../redux/actions/authAction'
import Loading from './Loading'
export default ({ children, ...props }) => {

    const auth = useSelector(state => state.auth);
    const accessToken = tokenManager.getAccessToken()
    const accessGranted = props.admin ?
        (auth.user.roles.includes('admin') ? true : false) : (auth.isAuthenticated ? true : false)


    if (auth.loading && accessToken) {
        return (< Route {...props}
            render={({ location }) =>
                accessGranted ? (children) :
                    (
                        <Loading></Loading>)
            }
        >
        </Route >)
    }


    return (< Route {...props}
        render={({ location }) =>
            accessGranted ? (children) :
                (
                    <Redirect to={{
                        pathname: "/login",
                        state: { from: location }
                    }}></Redirect>)
        }
    >
    </Route >)
}