import bootstrap from './bootstrap.png';
import css from './css.png';
import electron from './electron.png';
import firebase from './firebase.png';
import html from './html.png';
import javascript from './javascript.png';
import jquery from './jquery.png';
import mongo from './mongo.png';
import node from './node.png';
import react from './react.png';

export default { bootstrap, css, electron, firebase, html, javascript, jquery, mongo, node, react }