import React from 'react'
import Logo from './logo-glow.png'
// import Footer from '../Footer/index'


export default function index() {
    return (
        <div className="vh-100 d-flex flex-column justify-content-between ">
            <div style={{
                background: "linear-gradient(261.2deg, #29678B 6.71%, #0F1D35 43.34%)",
                display: "flex",
                justifyContent: "center"
            }}>
                <a href="https://ingles.programadorbr.com">
                    <img style={{ width: "70px", cursor: "pointer" }} src={Logo}>
                    </img>
                </a>
            </div>
            <iframe className="flex-fill" allow="microphone" style={{ border: "none", width: "100%" }} src="english?phrases=A%20software%20engineer%20is%20a%20person%3Bwho%20applies%20the%20principles%20of%20software%20engineering%3Bto%20the%20design%2C%20development%2C%20maintenance%2C%20testing%3Band%20evaluation%20of%20computer%20software."></iframe>
        </div>
    )
}
