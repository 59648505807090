import React, { useState, Fragment, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { Card, Button } from 'react-bootstrap'
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";

import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon
} from "react-share";
export default function Share(props) {

    const [show, setShow] = useState(false)
    const location = useLocation()
    const shareDialogView = useRef()
    const url = document.location.href
    // const url = "https://programadorbr.com"

    const copyLink = () => {
        navigator.clipboard.writeText(`${url}`).then(() => {
            alert('Link copiado')
        });
    }

    const dismiss = (e) => {
        if (e.target === shareDialogView.current)
            setShow(false)
    }

    const shareDialog = (show) => {
        return show ? <div ref={shareDialogView} className={`fullscreen d-flex justify-content-center align-items-center `} onClick={dismiss}>
            <Card className="p-5 d-flex flex-md-row  " >
                <Button onClick={copyLink} className="m-1 " >Copiar Link</Button>
                <FacebookShareButton url={url} className="m-1"><FacebookIcon size={48} round={true}></FacebookIcon></FacebookShareButton>
                <TwitterShareButton url={url} className="m-1"><TwitterIcon size={48} round={true}></TwitterIcon></TwitterShareButton>
                <WhatsappShareButton url={url} className="m-1" >< WhatsappIcon size={48} round={true}></WhatsappIcon></WhatsappShareButton>
                <TelegramShareButton url={url} className="m-1">< TelegramIcon size={48} round={true}></TelegramIcon></TelegramShareButton>
                <EmailShareButton url={url} className="m-1"><EmailIcon size={48} round={true}></EmailIcon></EmailShareButton>
                <LinkedinShareButton url={url} className="m-1"><LinkedinIcon size={48} round={true}></LinkedinIcon></LinkedinShareButton>
            </Card>
        </div> : <Fragment></Fragment>
    }

    return (
        <div className={`${props.className} d-flex`}>
            <Button className="flex-fill" variant="outline-primary" onClick={() => {
                setShow(true)
            }}>Compartilhar</Button>
            {shareDialog(show)}
        </div>


    )
}
