import React from 'react'

export default function Digit(props) {
    return (
        <div className="digit "> <div className="digitValue euro txt-grad-dark-blue"
            data-text={props.value}>
            {props.value}
        </div>
            <div className="digitUnit euro txt-grad-dark-blue" data-text={props.unit}>
                {props.unit}
            </div>
        </div>

    )
}
