import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'
import PrivateRoute from "../../PrivateRoute"
import ShortLinks from '../ShortLinks'
import LinkTrees from '../LinkTrees'
import ShortLinksAdd from '../ShortLinks/ShortLinkAdd'
import VideoContent from '../VideoContent'
import VideoContentAdd from '../VideoContent/VideoContentAdd/VideoContentAdd'
import LinkTreesAdd from '../LinkTrees/LinkTreesAdd/'
import CrudList from '../Crud/List'
export default function Content(props) {
    const location = useLocation()
    const modules = [
        // { name: 'videocontents', component: VideoContent, componentAdd: VideoContentAdd },
        // { name: 'shortlinks', component: ShortLinks, componentAdd: ShortLinksAdd },
        // { name: 'linktree', componentAdd: LinkTreesAdd, showOnList: ["endpoint", "description"] },
        { name: 'capture', componentAdd: LinkTreesAdd, showOnList: ["endpoint", "description"] },

    ]

    const routes = []

    modules.forEach(module => {
        routes.push(<PrivateRoute component={module.componentAdd} path={`/dashboard/${module.name}/edit`}>
        </PrivateRoute>)
        routes.push(<PrivateRoute component={module.componentAdd} path={`/dashboard/${module.name}/add`}>
            <h4>Add {module.name}</h4>
            <module.componentAdd></module.componentAdd>
        </PrivateRoute>)
        routes.push(
            <PrivateRoute path={`/dashboard/${module.name}`}>
                <h4 className="text-capitalize">{module.name}</h4>
                {module.component ?
                    <module.component></module.component> :
                    <CrudList module={module.name} showOnList={module.showOnList} ></CrudList>
                }
            </PrivateRoute>
        )
    })

    return (



        <div className={`${props.className} px-4 `}>
            <Switch>

                {routes.map(route => {
                    return route
                })}



                <PrivateRoute component={VideoContentAdd} path="/dashboard/videocontents/edit">
                </PrivateRoute>
                <PrivateRoute path="/dashboard/videocontents/add">
                    <h4>Add Video Content</h4>
                    <VideoContentAdd></VideoContentAdd>
                </PrivateRoute>
                <PrivateRoute path="/dashboard/videocontents">
                    <h4>Video Contents</h4>
                    <VideoContent></VideoContent>
                </PrivateRoute>
                <PrivateRoute component={ShortLinksAdd} path="/dashboard/shortlinks/edit">
                </PrivateRoute>
                <PrivateRoute path="/dashboard/shortlinks/add">
                    <h4>Add Shortlink </h4>
                    <ShortLinksAdd></ShortLinksAdd>
                </PrivateRoute>
                <PrivateRoute path="/dashboard/shortlinks">
                    <h4>Short Links</h4>
                    <ShortLinks></ShortLinks>
                </PrivateRoute>

                <PrivateRoute component={LinkTreesAdd} path="/dashboard/linktree/edit">
                </PrivateRoute>
                <PrivateRoute path="/dashboard/linktrees/add">
                    <h4>Add LinkTree</h4>
                    <LinkTreesAdd></LinkTreesAdd>
                </PrivateRoute>
                <PrivateRoute path="/dashboard/linktree">
                    <h4>Link Trees</h4>
                    <LinkTrees></LinkTrees>
                </PrivateRoute>
                <PrivateRoute path="/dashboard/uploads">
                    <h4>Uploads</h4>
                </PrivateRoute>


            </Switch>

        </div >
    )
}
