import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Login from './components/Login';

import { useSelector, useDispatch } from 'react-redux'

import PrivateRoute from './components/PrivateRoute';

import Dashboard from './components/Dashboard'
import authController from './components/Login/authController'
import tokenManager from './components/Login/tokenManager'
import CertificateStudent from './components/Certificate/CertificateEmail';
import CertificateView from './components/Certificate/CertificateView';
import CertificateVerification from './components/Certificate/CertificateId'
import ContentTemplate from './components/VideoTemplates/ContentTemplate';

import ReactPixel from 'react-facebook-pixel';
import LinkTreeTemplate from './components/LinkTreeTemplate';
import MemoryGame from './components/MemoryGame/MemoryGame';
import PictureFilter from './components/PictureFilter';
import FiveSteps from './components/Captures/FiveSteps';
import CodeGenarator from './components/English/CodeGenarator';
import Trainer from './components/English/Trainer';
import SpeakTool from './components/SpeakTool'






export default function App(props) {

    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch()
    const advancedMatching = {};
    const options = {
        autoConfig: true, // set pixel's autoConfig
        debug: false, // enable logs
    };
    useEffect(() => {
        ReactPixel.init('330504420914076', null, options);
        ReactPixel.pageView(); // For tracking page view

        const accessToken = tokenManager.getAccessToken()
        // console.log(auth, accessToken)
        if (!auth.isAuthenticated && accessToken) {
            dispatch(authController.authenticateWithToken(accessToken))
        }

    }, [])


    return (
        <Router>
            <Switch>
                <Route path="/c">
                    <ContentTemplate />
                </Route>
                <Route path="/l">
                    <LinkTreeTemplate />
                </Route>
                <Route path="/login">
                    <Login></Login>
                </Route>
                <Route exact path="/certificado">
                    <CertificateStudent />
                </Route>
                <Route exact path="/certificado/:id">
                    <CertificateView></CertificateView>
                </Route>
                <Route exact path="/autenticidade">
                    <CertificateVerification></CertificateVerification>
                </Route>
                <Route exact path="/autenticidade/:id">
                    <CertificateView></CertificateView>
                </Route>
                <PrivateRoute path="/dashboard*">
                    <Dashboard />
                </PrivateRoute>
                {/* <Route path="/e/captura">
                    <FiveSteps />
                </Route> */}
                <Route path="/memoria">
                    <MemoryGame />
                </Route>
                <Route path="/filter">
                    <PictureFilter />
                </Route>
                <Route path="/english/create">
                    <CodeGenarator />
                </Route>
                <Route path="/english">
                    <Trainer />
                </Route>

                <Route path="/curso-ingles-para-devs-exclusivo">
                    <SpeakTool />
                </Route>
                <Route path="*" ><h1>404</h1></Route>
            </Switch>
        </Router>
    )


}


// Certificado

// Conteúdo

// Membros - Login

// Admin

// Marketing

