import React, { useState, useEffect } from 'react';

import logo from '../../assets/logo.png'

import Button from '../common/Button'
import SimpleInput from '../common/SimpleInput'

import authController from './authController'

import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'

import LoginPage from './LoginPage'
import LoginContainer from './LoginContainer'

export default function Login(props) {

    const auth = useSelector(state => state.auth)
    const [errorLogin, setErrorLogin] = useState(false)

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const dispatch = useDispatch();
    const loginUser = () => {
        dispatch(authController.authenticateWithEmailAndPassword({ email, password }))
    }

    useEffect(() => {
        console.log("LOG:", auth)
        if (auth.isAuthenticated !== null && auth.isAuthenticated == false) {

            setErrorLogin(true)
        }

    }, [auth])

    if (auth.isAuthenticated) {
        return < Redirect to='/dashboard' />
    }

    const errorMessage = () => {
        if (errorLogin) {
            setTimeout(() => {
                setErrorLogin(false)
            }, 3000)
            return <div className="alert alert-danger" role="alert">Dados incorretos</div>
        }
    }

    return (
        <LoginPage>

            <LoginContainer>
                <img src={logo} alt="Logo"></img>
                <SimpleInput placeholder="Email" onChange={(e) => { setEmail(e.target.value) }} />
                <SimpleInput type="password" placeholder="senha" onChange={(e) => { setPassword(e.target.value) }} />

                {/* <a>Esqueci a senha</a> */}
                <Button small onClick={loginUser} >Login</Button>
                {/* <div>Não tem um login? <Link ><StyledText color="blue">Faça seu cadastro</StyledText></Link></div> */}
                {errorMessage()}
            </LoginContainer>
        </LoginPage>
    )


}


