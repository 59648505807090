import React, { useRef, useEffect, useState } from 'react'
import Share from './Share'
import YoutubePlayer from 'react-youtube-player'
import ReactPixel from 'react-facebook-pixel';
import YoutubePlayerVideo from './YoutubePlayerVideo';
import VimeoPlayerVideo from './VimeoPlayerVideo';
import { propTypes } from 'react-bootstrap/esm/Image';

export default function MainVideo(props) {


    function getVideoMain() {
        console.log("MAIN Video", props.video)
        if (props.video) {
            if (props.video.platform == "youtube")
                return <YoutubePlayerVideo {...props}></YoutubePlayerVideo>
            else {
                return <VimeoPlayerVideo {...props}></VimeoPlayerVideo>
            }
        } else {
            return <h2 className="container">Video não encontrado</h2>
        }
    }

    return (
        <>{getVideoMain()}</>
    )
}
