
const filters = {
    initial: [
        {
            name: "contrast",
            unit: "%",
            defaultValue: "100",
            min: "0",
            max: "500"
        },
        {
            name: "brightness",
            unit: "%",
            defaultValue: "100",
            min: "0",
            max: "500"
        },
         {
            name: "grayscale",
            unit: "%",
            defaultValue: "0",
            min: "0",
            max: "100"
        },
        
        {
            name: "saturate",
            unit: "%",
            defaultValue: "100",
            min: "0",
            max: "300"
        },
        {
            name: "sepia",
            unit: "%",
            defaultValue: "0",
            min: "0",
            max: "100"
        },
        {
            name: "blur",
            unit: "px",
            defaultValue: "0",
            min: "0",
            max: "100"
        }
    ],

    filterString: (filters) => {
        return filters.map((filter) => {
            return `${filter.name}(${filter.value || filter.defaultValue}${filter.unit})`
        }).join(' ')
    }


}

export default filters