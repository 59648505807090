import React, { useState } from 'react'

export default function Filter({ filterChange, ...props }) {

    const [value, setValue] = useState(props.defaultValue)

    const handleChange = (event) => {
        const name = props.name
        const unit = props.unit
        const value = event.target.value || props.defaultValue || 0
        filterChange({ name, unit, value })
        setValue(value)
    }

    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    return (
        <div className="d-flex flex-column align-items-center w-75">
            <label htmlFor={props.name}>{capitalize(props.name)} </label>
            <input className="w-100" id={props.name} type="range" onChange={handleChange} max={props.max || 100} min={props.min || 0} value={value}></input>
        </div>
    )
}
