
const getVideoContents = () => {
    return new Promise((resolve, reject) => {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        var config = {
            method: 'POST',
            headers: headers,
        };
        fetch('/content/all', config).then(async response => {
            if (response.status >= 400) {
                reject("Unauthorized")
            }
            console.log(response)
            return response.json()
        }
        ).then(json => {
            resolve(json)
        }).catch(error => {
            console.log(error)
            reject(error)
        })

    })
}

const getVideosForContentId = (contentId) => {
    return new Promise((resolve, reject) => {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        var config = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ key: "contentId", value: contentId })
        };
        console.log("/content/get")
        fetch('/content/get', config).then(async response => {
            console.log("/content/get")
            console.log(response)

            if (response.status >= 400) {
                reject("Unauthorized")
                return
            }
            return response.json()
        }
        ).then(json => {
            console.log("JSON", json)
            resolve(json)
        }).catch(error => {
            console.log(error)
            reject(error)
        })

    })
}

const addVideoContent = (accessToken, contentId, description, videos) => {

    return new Promise((resolve, reject) => {

        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("x-auth-token", accessToken);

        const body = JSON.stringify({ contentId, description, videos })

        var config = {
            method: 'POST',
            headers: headers,
            body
        };
        fetch('/content/add', config).then(async response => {
            return response.json()
        }
        ).then(json => {
            resolve(json)
        }).catch(error => {
            console.log(error)
            reject(error)
        })

    })
}

const updateVideoContent = (accessToken, _id, contentId, description, videos) => {

    return new Promise((resolve, reject) => {

        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("x-auth-token", accessToken);

        const body = JSON.stringify({ _id, contentId, description, videos })

        var config = {
            method: 'POST',
            headers: headers,
            body
        };
        fetch('/content/update', config).then(async response => {
            return response.json()
        }
        ).then(json => {
            resolve(json)
        }).catch(error => {
            console.log(error)
            reject(error)
        })

    })
}

const removeVideoContent = (_id, accessToken) => {
    console.log("Remove", _id)
    return new Promise((resolve, reject) => {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("x-auth-token", accessToken);

        const body = JSON.stringify({ _id })

        var config = {
            method: 'POST',
            headers: headers,
            body
        };
        fetch('/content/remove', config).then(async response => {
            if (response.status > 400) {
                throw response
            }
            return response.json()
        }
        ).then(json => {
            resolve(json)
        }).catch(error => {
            console.log(error)
            reject(error)
        })

    })
}

export { getVideoContents, addVideoContent, removeVideoContent, updateVideoContent, getVideosForContentId }