
import 'bootstrap/dist/css/bootstrap.min.css';
import AccentSelector from './AccentSelector';
import Talker from './Talker';
import { EnglishTrainerProvider } from './EnglishTrainerProvider';
import { Card } from 'react-bootstrap';

import React from 'react'
// const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

const isChrome = (() => {
    var isChromium = window.chrome;
    var winNav = window.navigator;
    var vendorName = winNav.vendor;
    var isOpera = typeof window.opr !== "undefined";
    var isIEedge = winNav.userAgent.indexOf("Edge") > -1 || winNav.userAgent.indexOf("Edg") > -1;
    var isIOSChrome = winNav.userAgent.match("CriOS");
    var isBrave = (navigator.brave || false)

    if (isIOSChrome) {
        // is Google Chrome on IOS
        return false
    } else if (
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        isOpera === false &&
        isIEedge === false &&
        isBrave === false
    ) {
        // is Google Chrome
        return true;
    } else {
        // not Google Chrome 
        return false
    }

})()
console.log("is Chrome:", isChrome)

function Trainer() {
    function getSearchParameters() {
        var prmstr = window.location.search.substr(1);
        return prmstr != null && prmstr != "" ? transformToAssocArray(prmstr) : {};
    }

    function transformToAssocArray(prmstr) {
        var params = {};
        var prmarr = prmstr.split("&");
        for (var i = 0; i < prmarr.length; i++) {
            var tmparr = prmarr[i].split("=");
            params[tmparr[0]] = tmparr[1];
        }
        return params;
    }

    const params = getSearchParameters();
    const sentences = decodeURIComponent(params.phrases).split(";")
    console.log(sentences)
    // const sentences = [
    //   "When I was learning to code I usually confused the maths types of numbers to the types of variables that are numbers.",
    //   "I used to think that an Integer (int) was a Natural number and Float would represent Real numbers.",
    //   "However, I learned later that types of variables are only ways to represent maths numbers. ",
    //   "So, an int could present Natural numbers but also Whole, or Integer numbers.",
    //   "A float could represent Rational and Real numbers but I could also write a Whole number with a Float, the difference is that this representation will have decimal places.",
    //   "confused", "learned", "used", "escaped", "maths", "math",
    //   "Note that the development build is not optimized."
    // ]

    return (
        <div className="App container">

            {!isChrome ? <div className="container  mt-3">
                <Card>
                    <Card.Header>
                        Utilize o Google Chrome
          </Card.Header>
                    <Card.Body>
                        Nesse momento as aulas com reconhecimento de voz só estão disponíveis no Google Chrome
          </Card.Body>
                </Card>
            </div> :
                <EnglishTrainerProvider>
                    {/* <Recorder></Recorder> */}
                    <AccentSelector></AccentSelector>
                    <div style={{ textAlign: "end", color: "#bbb" }}>Beta</div>
                    {sentences.map(sentence =>
                        <Talker key={sentence}
                            sentence={sentence}
                        ></Talker>
                    )}
                    {/* <Transcriber></Transcriber> */}
                </EnglishTrainerProvider>}

        </div>
    );
}

export default Trainer;