const getAllCertificatesFrom = (email) => {
    console.log(email)
    return new Promise((resolve, reject) => {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        const body = JSON.stringify({ email })
        var config = {
            method: 'POST',
            headers: headers,
            body
        };
        fetch('/api/certificate/getcertificatesfromemail', config).then(async response => {
            if (response.status >= 400) {
                const error = await response.json()
                console.log(error)
                reject(error);
            }
            return response.json()
        }
        ).then(json => {
            resolve(json)
        }).catch(error => {
            console.log(error)
            reject(error)
        })

    })
}



export { getAllCertificatesFrom }