import React, { createContext, useState, useEffect } from "react";

export const EnglishTrainerContext = createContext()

export function EnglishTrainerProvider(props) {
    const [selectedVoice, setSelectedVoice] = useState(null)
    const [speechRate, setSpeechRate] = useState(1.0)
    const [listenedSentence, setListenedSentence] = useState("")
    const [actualSentence, setActualSentence] = useState("")

    useEffect(() => {
        console.log(selectedVoice)
    }, [selectedVoice])

    useEffect(() => {
        console.log(speechRate)
    }, [speechRate])
    return <EnglishTrainerContext.Provider value={{
        selectedVoice,
        setSelectedVoice,
        speechRate,
        setSpeechRate,
        listenedSentence,
        setListenedSentence,
        actualSentence,
        setActualSentence
    }}>
        {props.children}
    </EnglishTrainerContext.Provider>

}