import React from 'react'
import images from '../images/images'

export default function CardElement(props) {
    return (
        <div onClick={() => { props.handleFlip(props.card) }} id={props.card.id} className={`memory-card ${props.card.flipped ? "flip" : ""}`}>
            <div className="card_front">
                <img className="icon"
                    src={images[props.card.icon]}
                    alt={props.card.icon}></img>
            </div>
            <div className="card_back">
                {"</>"}
            </div>
        </div>
    )
}
