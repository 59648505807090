import React, { useEffect, useState } from 'react'
import { getShortLinks } from './ShortLinksContoller'
import { useSelector } from 'react-redux'
import ShortLinksList from './ShortLinksList';
import { Link } from 'react-router-dom'

export default function ShortLinks(props) {
    const auth = useSelector(state => state.auth);

    const [shortLinks, setShortLinks] = useState([])
    const [unauthorized, setUnauthorized] = useState(false)

    useEffect(() => {
        getShortLinks(auth.accessToken).then(shorts => {
            setShortLinks(shorts)
            console.log(shorts)
        }).catch(error => {
            setUnauthorized(true)
            console.log(error)
        })
    }, [])

    function itemRemoved(id) {
        setShortLinks(prev => prev.filter(item => item._id != id))
    }

    function getShorts() {
        if (unauthorized)
            return <div>Acesso Negado</div>
        return <div>
            <header className="d-flex justify-content-end mb-2">
                <Link className="btn btn-primary" to={`/dashboard/shortlinks/add`}>+</Link>

            </header>
            <ShortLinksList itemRemoved={itemRemoved} shortLinks={shortLinks}></ShortLinksList>
        </div>

    }

    return (
        <div>
            {getShorts()}
        </div>
    )
}
