import styled from 'styled-components'

import {
    mainFont
} from '../common/CssDefaultAttributes'

export default styled.div`
font-family: ${mainFont};

display:flex;
flex-direction: column;
justify-content:center;
align-items:center;
width:100vw;
height: 100vh;
background: linear-gradient(212.01deg, #29678B 0%, #0E1C34 100%);
`