function formatNumber(number) {
    return number < 10 ? `0${number}` : `${number}`
}

function dateToInputDate(date) {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1
    const day = date.getUTCDate()
    const hour = date.getUTCHours()
    const minutes = date.getUTCMinutes()
    const time = `${year}-${formatNumber(month)}-${formatNumber(day)}T${formatNumber(hour)}:${formatNumber(minutes)}`
    return time
}

function inputDateToDate(inputDate) {
    const [date, time] = [...inputDate.split("T")]
    const [year, month, day] = [...date.split("-")]
    const [hour, minutes] = [...time.split(":")]
    console.log(year, month, day)
    console.log(hour, minutes)
    var unlockDate = new Date(Date.UTC(year, month - 1, day, hour, minutes));
    console.log(unlockDate)
    const now = new Date(Date.now())
    console.log(now)
    console.log((unlockDate > now))
    return unlockDate
}

export { dateToInputDate, inputDateToDate }