
import styled, { css } from 'styled-components'

import {
  getColorGradient,
  // mainFont
} from './CssDefaultAttributes'

import Margins from './Margins'

const StyledText = styled.span`
@media (min-width: 600px) {
background:${getColorGradient()};
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

${props =>
    props.color &&
    css`
      background: ${getColorGradient(props.color)};
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  `};


}

${Margins.mt}
${Margins.mb}
`


export default StyledText