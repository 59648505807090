import React from 'react'

export default function InputFile({ handleSelectFile }) {
    return (
        <div className="d-flex justify-content-center mt-3">
            <input id="file" accept="image/png, image/jpeg" type="file" onChange={handleSelectFile}></input>
            <label className="btn btn-primary" htmlFor="file">Escolha um arquivo</label>
        </div>
    )
}
