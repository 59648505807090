import React from 'react'
import SideBar from './Sidebar'
import Content from './Content'

export default function Dashboard() {
    return (
        <div className="container">
            <header className="p-2">
                <h1>Admin</h1>
            </header>
            <section className="d-flex ">
                <SideBar className=" " />
                <Content className=" flex-grow-1" />
            </section>
        </div>
    )
}
