

import {
    REGISTER_FAIL,
    REGISTER_SUCCESS,
    LOGIN_SUCCESS,
    AUTH_ERROR,
    LOGOUT,
    LOGIN_FAIL
} from './types'
export default {
    loginSuccess: (payload) => {
        console.log(LOGIN_SUCCESS)
        return { type: LOGIN_SUCCESS, payload }
    },

    authError: (payload) => {
        console.log(AUTH_ERROR)

        return { type: AUTH_ERROR, payload }
    },

    logoutAction: () => {
        return { type: LOGOUT }
    }
}