import styled, { css } from 'styled-components'


const mt = props =>
    props.mt &&
    css`margin-top: 20px;`;

const mb = props =>
    props.mt &&
    css`margin-bottom: 20px;`;

export default { mt, mb };