import React, { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'
import { getVideoContents } from './VideoContentController';
import VideoContentsList from './VideoContentsList';

export default function VideoContent(props) {
    // const auth = useSelector(state => state.auth);

    const [videoContents, setVideoContents] = useState([])
    const [unauthorized, setUnauthorized] = useState(false)

    useEffect(() => {
        console.log("VIDEO CONTENT")
        getVideoContents().then(contents => {
            console.log("contents")

            setVideoContents(contents)
            console.log(contents)
        }).catch(error => {
            // setUnauthorized(true)
            console.log(error)
        })
    }, [])

    // function itemRemoved(id) {
    //     setShortLinks(prev => prev.filter(item => item._id != id))
    // }
    function itemRemoved(id) {
        setVideoContents(prev => prev.filter(item => item._id != id))
    }

    function getContents() {
        if (unauthorized)
            return <div>Acesso Negado</div>
        return <div>

            <header className="d-flex justify-content-end mb-2">
                <Link className="btn btn-primary" to={`/dashboard/videocontents/add`}>+</Link>
            </header>
            {/* <VideoContentsList itemRemoved={} shortLinks={videoContents}></VideoContentsList> */}
            <VideoContentsList itemRemoved={itemRemoved} videoContents={videoContents}></VideoContentsList>
        </div>

    }

    return (
        <div>
            {getContents()}
        </div>
    )
}
