import React from 'react';


export default function Loading(props) {

    return (
        <div>
            Loading
        </div>
    )


}