import React, { Fragment, useState, useEffect } from 'react'


import Button from '../common/Button'
import StyledLink from '../common/StyledLink'
import Label from '../common/Label'


function DownloadButton(props) {
    function certifitatePath(cert) {
        return `/certificado/${cert}`
    }
    return (<StyledLink to={certifitatePath(props.certificate.id)} target="_blank"> <Button small noshadow mb outline color="blue"> {props.certificate.moduleName}</Button></StyledLink>)
}

function DownloadButtons(props) {
    const [title, setTitle] = useState("")

    useEffect(() => {
        if (props.certificates.length > 0) {
            setTitle("CERTIFICADOS DISPONÍVEIS")
        }
        else {
            setTitle()
        }
    }, [props.certificates])

    return (<Fragment>
        <Label>{props.title}{title}</Label>
        {props.certificates.map((certificate, index) =>
            <DownloadButton key={index} certificate={certificate}></DownloadButton>)}
    </Fragment>)

}


export default DownloadButtons