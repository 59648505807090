import React, { useRef, useState, useEffect } from 'react'
import Divider from './Divider'
import Digit from './Digit'
import { isMobile } from 'react-device-detect';

import './popup.css'
const dueDate = new Date(2020, 9, 25, 23, 59)

export default function Popup(props) {
    const canShow = useRef(true);
    const [show, setShow] = useState(false)


    function showOn() {
        if (canShow.current) {
            setShow(true)
            canShow.current = false
        }
    }


    useEffect(() => {
        if (Date.now().valueOf() > dueDate.valueOf()) {
            return
        }
        if (isMobile) {
            setTimeout(() => {
                showOn()
            }, 2000)
        } else {
            document.onmouseleave = function (e) {
                showOn()
            };
        }



    }, [])
    useEffect(() => {

    }, [show])

    function onClose() {
        setShow(false)
    }
    function handleClick(e) {
        if (e.target.id === "popupStage") {
            onClose()
        }
    }

    return (
        <div onClick={handleClick} id="popupStage" className={show ? "" : "hide"}>
            <PopupWindow onClose={onClose}></PopupWindow>
        </ div>
    )
}
function PopupWindow(props) {


    function Feature(props) {

        const style = {
            backgroundColor: "#FFCD33",
            padding: "15px",
            borderRadius: "10px",
            margin: "12px",
            display: "flex",
            justifyCointent: "center",
            alignItems: "center"
        }

        return <div style={style}>
            <strong>{props.children}</strong>
        </div>
    }

    return (
        <div className="popupWindow">
            <header className="popupHeader">
                <div className="popupLogo">
                    <a href="index.html">
                        <img src="/Images/Blue.svg" height="50px" alt="Programador BR" />
                    </a>
                </div>
                <h2 data-text="Promoção por tempo Limitado" className=" popupTitle big txt-grad-dark-blue text-center">CURSO DEV WEB FULLSTACK</h2>

                <h3 data-text="Promoção por tempo Limitado" className="popupTitle  text-center">OFERTA POR TEMPO LIMITADO!</h3>

            </header>
            <Feature>
                <div className="popupText euro">5% de desconto na compra À VISTA.</div>
            </Feature>
            <Countdown limitTime={dueDate}></Countdown>
            <div className="buttons">
                <Button onClick={props.onClose} className="btn btn-outline-primary">PERDER A OPORTUNIDADE</Button>
                <Button onClick={() => {
                    window.location.href = "https://programadorbr.com/avista?src=popup";
                }} className="btn btn-orange">APROVEITAR A OFERTA</Button>
            </div>
        </div>
    )
}


function Countdown(props) {

    const [days, setDays] = React.useState(0)
    const [hours, setHours] = React.useState(0)
    const [minutes, setMinutes] = React.useState(0)
    const [seconds, setSeconds] = React.useState(0)

    const [tick, setTick] = React.useState(null)
    React.useEffect(() => {
        console.log("teste")

        setTick(setInterval(() => {

            const remainTime = props.limitTime.valueOf() - Date.now().valueOf()

            const remainTimeInSeconds = Math.floor(remainTime / 1000);
            const remainTimeInMinutes = Math.floor(remainTimeInSeconds / 60);
            const remainTimeInHours = Math.floor(remainTimeInMinutes / 60);
            const remainTimeInDays = Math.floor(remainTimeInHours / 24);

            setDays(remainTimeInDays);
            setHours(remainTimeInHours % 24)
            setMinutes(remainTimeInMinutes % 60)
            setSeconds(remainTimeInSeconds % 60)

        }, 200))

        return () => {
            if (tick) {
                clearInterval(tick)
                setTick(null)
            }
        }
    }, [])

    function formatNumber(number) {
        return ("0" + number).slice(-2);
    }
    return (
        <div className="countdown">
            <Digit value={formatNumber(days)} unit="Dias"></Digit>
            <Divider />
            <Digit value={formatNumber(hours)} unit="Horas"></Digit>
            <Divider />
            <Digit value={formatNumber(minutes)} unit="Minutos"></Digit>
            <Divider />
            <Digit value={formatNumber(seconds)} unit="Segundos"></Digit>
        </div>
    )
}



function Button(props) {
    return (<div onClick={props.onClick}
        style={{ padding: "10px 10px", minHeight: 0, fontSize: "14px" }}
        className={(() => { return " txt-white  pulse  " + props.className })()}>
        {props.children}
    </ div>)
}