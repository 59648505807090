import styled from 'styled-components'

import {
    mainFont
} from './CssDefaultAttributes'


const Label = styled.label`
font-family: ${mainFont};
font-style: normal;
font-weight: 850;
font-size: 18px;
line-height: 25px;
color: #0E1C34;
text-transform: uppercase;
`

export default Label