import React, { useState, Fragment } from 'react'
import Container from '../Container'

import logo from '../../assets/logo.png'
import StyledText from '../common/StyledText'
import Display from '../common/Display'
import Input from '../common/Input'
import Button from '../common/Button'

import { getAllCertificatesFrom } from './controller/CertificateController'
import DownloadButtons from './DownloadButtons'


export default function CertificateStudent(props) {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [certificates, setCertificates] = useState([])
    const [loading, setLoading] = useState(false)


    function getCertificates() {
        setLoading(true)
        setMessage("")
        getAllCertificatesFrom(email).then(certificates => {
            if (!certificates || certificates.length == 0) {
                setMessage('Não existem certificados para você nesse momento')
                setCertificates([])
            } else {
                console.log(certificates)
                setCertificates(certificates)
            }
        }).catch(err => {
            console.log(err);
            setMessage('Ocorreu um erro')
            setCertificates([])
        })

    }

    function stopLoading() {
        setLoading(false)
    }

    const handleSubmit = (event) => {
        getCertificates()
        event.preventDefault();
    }

    return (
        <Container>
            <img src={logo} alt="Logo"></img>
            <Display ><StyledText color="dark">CURSO<br /> DESENVOLVIMENTO<br /> WEB FULL STACK</StyledText></Display>
            <div style={{ maxWidth: 400 }}>
                <form onSubmit={handleSubmit}>
                    <Input id="email" type="email" onChange={(e) => { setEmail(e.target.value); console.log('email', email) }} title="Digite seu email" placeholder="Email"></Input>
                    <Button mb mt type="submit" loading={loading}>Consultar certificados</Button>
                    <StyledText>{message}</StyledText>
                </form>
                <DownloadButtons certificates={certificates}></DownloadButtons>
            </div>
        </Container >
    )
}