
import authAction from '../../redux/actions/authAction'
import tokenManager from './tokenManager'

export default {
    register: async ({ name, email, password }) => {
        return new Promise((resolve, reject) => {
            var headers = new Headers();
            headers.append("Content-Type", "application/json");

            var config = {
                method: 'POST',
                headers: headers,
            };
            const body = JSON.stringify({ name, email, password })

            fetch('/api/register', config, body).then(response => response.json()).then(json => {
                resolve(json);
            }).catch(error => {
                reject(error)

            })
        })
    },


    // export const authenticateWithToken = async (accessToken) => {
    //     return new Promise((resolve, reject) => {
    //         var headers = new Headers();
    //         headers.append("Content-Type", "application/json");
    //         headers.append("x-auth-token", accessToken);

    //         var config = {
    //             method: 'POST',
    //             headers: headers,
    //         };
    //         console.log(accessToken)
    //         fetch('/api/login', config).then(async response => {
    //             if (response.status >= 400) {
    //                 const error = await response.json()
    //                 return reject(error.message)
    //             }
    //             return response.json()
    //         }).then(json => {
    //             console.log(json)
    //             resolve(json);
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // }

    // export const authenticateWithEmailAndPassword = async (credentials) => {
    //     return new Promise((resolve, reject) => {

    //         var headers = new Headers();
    //         headers.append("Content-Type", "application/json");
    //         const body = JSON.stringify(credentials)

    //         var config = {
    //             method: 'POST',
    //             headers: headers,
    //             body
    //         };
    //         console.log(body)
    //         const loginData = {}

    //         fetch('/api/login', config).then(async response => {
    //             if (response.status >= 400) {
    //                 const error = await response.json()
    //                 return reject(error)
    //             }
    //             let accessToken = response.headers.get("x-auth-token");
    //             let refreshToken = response.headers.get("x-refresh-token");
    //             loginData.accessToken = accessToken;
    //             loginData.refreshToken = refreshToken
    //             return response.json()
    //         }
    //         ).then(json => {
    //             loginData.data = json
    //             resolve(loginData);
    //         }).catch(error => {
    //             reject(error)
    //         })
    //     })
    // }

    authenticateWithToken: (accessToken) => {
        // return new Promise((resolve, reject) => {
        return (dispatch) => {
            console.log("AUTH WITH TOKEN", accessToken)

            var headers = new Headers();
            headers.append("Content-Type", "application/json");
            headers.append("x-auth-token", accessToken);

            var config = {
                method: 'POST',
                headers: headers,
            };
            console.log(accessToken)
            fetch('/api/login', config).then(async response => {
                console.log("Response", response.status)

                if (response.status >= 400) {
                    console.log("Response", response)
                    const error = await response.json()
                    // return reject(error.message)
                    dispatch(authAction.authError(error))
                    throw error
                    // return
                } else {
                    console.log("Response Success", response)
                    return response.json()
                }
            }).then(json => {
                console.log("Login Token Sucess:", json)
                dispatch(authAction.loginSuccess(json))

                // resolve(json);
            }).catch(error => {
                console.log("Error", error)
                dispatch(authAction.authError())
            })
        }
        // })
    },

    authenticateWithEmailAndPassword: (credentials) => {
        return (dispatch) => {

            var headers = new Headers();
            headers.append("Content-Type", "application/json");
            const body = JSON.stringify(credentials)

            var config = {
                method: 'POST',
                headers: headers,
                body
            };
            // console.log(body)
            const loginData = {}

            fetch('/api/login', config).then(async response => {
                console.log(response)

                if (response.status >= 400) {
                    const error = await response.json()
                    dispatch(authAction.authError())
                    return
                }
                let accessToken = response.headers.get("x-auth-token");
                let refreshToken = response.headers.get("x-refresh-token");
                tokenManager.saveAccessToken(accessToken)
                tokenManager.saveRefreshToken(refreshToken)
                loginData.accessToken = accessToken;
                loginData.refreshToken = refreshToken
                console.log(response)
                return response.json()
            }
            ).then(json => {
                loginData.data = json
                console.log(json)
                dispatch(authAction.loginSuccess({ user: json.data }))
            }).catch(error => {
                dispatch(authAction.authError())
            })
        }
    }


}