import React, { useContext, useRef, useState } from 'react'
import { act } from 'react-dom/test-utils'
import { useLocation } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import './codegenerator.css'
export default function CodeGenarator() {
    let location = window.location

    const [phrases, setPhrases] = useState([])
    const [inputPhrase, setInputPhrase] = useState("")
    const [show, setShow] = useState(false)
    const textarea = useRef(null)
    const [swapItems, setSwapItems] = useState(null)


    function addPhrase(e) {
        e.preventDefault()
        setPhrases(prev => [...prev, inputPhrase])
        setInputPhrase("")
    }

    function removePhrase(index) {
        setPhrases(prev => [...prev].filter((item, i) => index != i))
    }

    function Phrases(props) {

        function handleOnDragEnd(result) {
            if (!result.destination) return;

            const items = [...phrases];
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            setPhrases(items)
        }
        return (
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="phrases">
                    {(provided) => (
                        <ul className="mt-3 list-group phrases" {...provided.droppableProps} ref={provided.innerRef}>
                            {props.phrases.map((phrase, index) => {
                                return (
                                    <Draggable key={index} index={index} draggableId={phrase}>
                                        {(provided) => (
                                            <li className=" d-flex flex-row justify-content-between align-items-center mb-2 p-2"
                                                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <div>{`"${phrase}"`}</div>
                                                <button className="btn btn-danger" onClick={() => props.removeItem(index)}>Delete</button></li>
                                        )}
                                    </Draggable>
                                )
                            })}
                            {provided.placeholder}

                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }

    function showCode(e) {
        e.preventDefault()
        console.log("Show")
        setShow(prev => !prev)
    }

    function Code(props) {
        const base = "https://" + location.host + "/english?phrases="
        console.log(base)
        function generateLink(phrases) {
            const phrasesString = phrases.reduce((prev, current) => {
                return (`${prev};${current}`)
            })
            return base + encodeURIComponent(phrasesString)
        }
        function generateEmbedCode(phrases) {
            return `<iframe allow="microphone" style="border:none;width:100%; height:500px" src="${generateLink(phrases)}"></iframe>`
        }

        function copyToCLipboard(link) {

            const value = link ? generateLink(phrases) : textarea.current.value

            navigator.clipboard.writeText(value).then(function () {
                /* clipboard successfully set */
                alert("Copied the text: " + value);
            }, function () {
                /* clipboard write failed */
                alert("Ocorreu um erro ");

            });
        }
        return (
            <div className="code-gen row justify-content-md-center align-items-center">
                <div className="card col col-lg-4 p-4">
                    <div className="d-flex flex-column">
                        <textarea className="p-2" disabled ref={textarea} value={generateEmbedCode(props.phrases)}>
                        </textarea>
                        <div className="mt-2">
                            <button className="btn btn-success mr-2" onClick={() => { copyToCLipboard(false) }}>Copy Code</button>
                            <button className="btn btn-success mr-2" onClick={() => { copyToCLipboard(true) }} link>Copy Link</button>

                            <button className="btn btn-danger" onClick={props.onClose}>Close</button>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    return (
        <div className="container">
            <form className="card d-flex flex-row p-2">
                <input className="flex-grow-1" placeholder="New Phrase" onChange={(e) => { setInputPhrase(e.target.value) }} value={inputPhrase}></input>
                <button className="btn btn-primary mx-2" onClick={addPhrase}>Add</button>
                <button className="btn btn-primary" onClick={showCode}>code</button>
            </form>
            <Phrases removeItem={removePhrase} phrases={phrases} />
            {show ? <Code phrases={phrases} onClose={showCode}></Code> : <></>}
        </div >
    )
}
