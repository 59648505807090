
import styled, { css } from 'styled-components'
import {
    mainFont
} from './CssDefaultAttributes'

const Display = styled.h1`
font-family: ${mainFont};
font-style: normal;
font-weight: 800;
line-height: 50px;
font-size: 44px;


@media (max-width: 600px) {
    line-height: 40px;
    font-size: 34px;
}

`

export default Display
