import React from 'react'

export default function CallToAction(props) {
    return (
        <>
            {props.video.callToActionOn ? <div className="d-flex">
                <a className={`btn btn-orange  flex-fill`} href={props.video.callToActionLink} >{props.video.callToActionTitle}</a>
            </div>
                : <></>}
        </>
    )
}
