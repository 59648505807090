import React from 'react'
import { Card, Button } from 'react-bootstrap'
import { TrashIcon, PencilIcon } from '@primer/octicons-react'
import { removeShortLink } from './ShortLinksContoller'

export default function ShortLinkItem(props) {

    const removeItem = (id) => {
        removeShortLink(id).then(data => {
            console.log(data)
            props.itemRemoved(data.id)
        }).catch(error => {
            console.log(error)
        })
    }


    return (
        <li>
            <Card className="p-2 mb-2 d-flex flex-row align-items-center justify-content-between">
                <div>
                    <Card.Title>{props.item.endpoint}</Card.Title>
                    <div>
                        <strong>Destination:</strong> <a href={props.item.destination}>{props.item.destination}</a>
                    </div>
                </div>
                <div className="d-flex">
                    <Button className="mr-3">
                        <PencilIcon size={24} />
                    </Button>
                    <Button variant="danger" className="" onClick={() => { console.log(props.item._id); removeItem(props.item._id) }}>
                        <TrashIcon size={24}  ></TrashIcon>
                    </Button>
                </div>
            </Card>
            {/* <Card>

            </Card> */}

        </li>
    )
}
