import React, { useState, useEffect } from 'react'
import { Button, Alert } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { addShortLink } from './ShortLinksContoller'

export default function ShortLinkAdd(props) {
    const history = useHistory()

    const [endpoint, setEndpoint] = useState("")
    const [destination, setDestination] = useState("")
    const [view, setView] = useState("")
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(false)
            }, 3000);
        }
    }, [error])

    function cancel() {
        history.goBack()
    }

    function save() {
        if (endpoint.replace(/\s+/g, '') == "" || destination.replace(/\s+/g, '') == "") {
            setError("Endpoint e Destination precisam estar preenchidos")
            return
        }
        addShortLink(endpoint, destination, view).then(success => {
            setSuccess("Endpoint salvo com sucesso")
            setTimeout(() => {
                history.goBack()
            }, 1000);
        }).catch(error => {
            console.log(error)
            setError("Não foi Possivel salvar")
        })
    }



    return (
        <div>
            <header className="d-flex justify-content-end">
                <Button onClick={cancel} variant="danger" className="mr-2  ">
                    Cancel
                </Button>
                <Button onClick={save}>
                    Save
                </Button>
            </header>
            <form className="d-flex flex-column mt-4">
                <input onChange={element => { setEndpoint(element.target.value) }} className="mb-3" name="endpoint" id="endpoint" placeholder="Endpoint"></input>
                <input onChange={element => { setDestination(element.target.value) }} className="mb-3" name="destination" id="destination" placeholder="Destination"></input>
                <input onChange={element => { setView(element.target.value) }} className="mb-3" name="view" id="view" placeholder="View"></input>
            </form>
            <Alert show={error} variant="danger">{error}</Alert>
            <Alert show={success} variant="success">{success}</Alert>

        </div>
    )
}
