import React from 'react'
import Item from './Item';

export default function ItemList(props) {

    return (
        <ul className="list-unstyled">{props.items.map((item, index) =>
            // <VideoContentItem itemRemoved={} key={index} item={item} />
            <Item {...props} key={index} item={item} />
        )}</ul>
    )
}
