import React from 'react'
import ShortLinkItem from './ShortLinkItem';

export default function ShortLinksList(props) {

    return (
        <ul className="list-unstyled">{props.shortLinks.map((item, index) =>
            <ShortLinkItem itemRemoved={props.itemRemoved} key={index} item={item} />

        )}</ul>
    )
}
