import styled from 'styled-components'
import {
    mainFont
} from './common/CssDefaultAttributes'

export default styled.div`
font-family: ${mainFont};
max-width: 900px;
flex-direction: column;
margin:auto ;
padding: 30px;

`

