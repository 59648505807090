import React, { useState, useEffect, Fragment } from 'react'
import { Link, useLocation } from 'react-router-dom'

import Navbar from '../../Navbar'
import MainVideo from '../MainVideo'
import Cards from '../Cards'
import EmailCapture from '../../EmailCapture'
import Banner from '../../Banner'
import Footer from '../../Footer'
import { getVideosForContentId } from '../../Dashboard/VideoContent/VideoContentController'
import '../../../assets/style.css'
import ReactPixel from 'react-facebook-pixel';
import Popup from '../../Popup/Popup'

export default function ContentTemplate() {
    const location = useLocation()
    const args = location.pathname.split('/')
    const [selected, setSelected] = useState(args[3] || 0)
    const [content, setContent] = useState()
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {

        console.log(args[2])

        if (!loaded)
            getVideosForContentId(args[2]).then(content => {
                console.log("content")
                setLoaded(true)
                setContent(content)
                setSelected(args[3] || 0)
                console.log(content)

                // ReactPixel.trackCustom(`content_${args[2]}`);

            }).catch(error => {
                // setUnauthorized(true)
                console.log(error)
            })
    }, [loaded])

    useEffect(() => {

        setLoaded(false)
        console.log("CHANGE SELECCTED")
    }, [location])

    useEffect(() => {
        if (content && getVideo(selected))
            ReactPixel.trackCustom(`content_${args[2]}`, { videoId: getVideo(selected).videoId });

    }, [selected, content])

    function cards() {
        if (content) {
            if (content.videos.length > 1)
                return <Cards videos={content.videos} selected={selected}></Cards>
        }
    }

    function getVideo(selected) {
        console.log(selected, Number(selected))
        if (Number.isNaN(Number(selected))||selected>10000) {
            console.log("NOT A NUMBER")
            const v = content.videos.filter(video => video.videoId == selected)[0]
            console.log(v)
            return v
        } else {
            console.log(" A NUMBER")
            return content.videos[selected]
        }
    }


    return (
        <div>
            <Navbar></Navbar>

            {content ? <MainVideo group={args[2]} video={getVideo(selected)} selected={selected}></MainVideo> : <Fragment></Fragment>}
            {/* {content ? <Cards videos={content.videos} selected={selected}></Cards> : <Fragment></Fragment>} */}
            {cards()}
            <EmailCapture></EmailCapture>
            <Banner src={args[2]}></Banner>
            <Footer></Footer>
            <Popup></Popup>
        </div>
    )

}
