import React, { Component } from 'react';
import styled from 'styled-components';


const StyledAnchor = styled.a`
    text-decoration: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
`;

export default StyledAnchor;