import React, { useEffect, useState } from 'react'
import Filter from './Filter'
import filterHelper from './filterHelper'
export default function FilterGroup({ updateFilter }) {

    const [filters, setFilters] = useState(filterHelper.initial)

    function filterChange(filter) {
        setFilters((prev => prev.map(f => f.name === filter.name ? filter : f)))
    }
    useEffect(() => {
        updateFilter(filterHelper.filterString(filters))
    }, [filters, updateFilter])

    return (
        <> {filterHelper.initial.map((filter, index) => <Filter key={index} {...filter} filterChange={filterChange}></Filter>)}</>
    )
}
