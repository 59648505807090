import React, { useState, Fragment } from 'react'

import Container from '../Container'

import logo from '../../assets/logo.png'
import StyledText from '../common/StyledText'
import Display from '../common/Display'
import Input from '../common/Input'
import Button from '../common/Button'
import { useHistory } from 'react-router-dom'

function certifitatePathBack(cert) {
    return `/api/certificate/${cert}`
}

export default function CertificateStudent(props) {
    const [identificador, setIdentificador] = useState("");
    const [message, setMessage] = useState("");

    const history = useHistory()

    const getCertificateFromId = () => {
        console.log("Getting cert")
        fetch(certifitatePathBack(identificador))
            .then(res => res.json())
            .then(data => {
                if (data.id) {
                    console.log("data", data)
                    history.push(`/certificado/${data.id}`)
                } else {
                    setMessage("Certificado não encontrado")
                }
            }).catch(err => {
                setMessage("Ocorreu um erro")
            })
    }


    const handleSubmit = (event) => {
        getCertificateFromId()
        event.preventDefault();
    }

    return (
        <Container>
            <img src={logo} alt="Logo"></img>
            <Display ><StyledText color="dark">CURSO<br /> DESENVOLVIMENTO<br /> WEB FULL STACK</StyledText></Display>
            <div style={{ maxWidth: 400 }}>
                <form onSubmit={handleSubmit}>
                    <Input id="certId" onChange={(e) => { setIdentificador(e.target.value) }} title="Digite o identificador do certificado" placeholder="Nº do certificado"></Input>
                    <Button type="submit" mt >Consultar certificado</Button>
                    <StyledText>{message}</StyledText>
                </form>
            </div>
        </Container >
    )
}