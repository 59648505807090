import React, { useState } from 'react'
import { Card, Button } from 'react-bootstrap'
import { TrashIcon, PencilIcon } from '@primer/octicons-react'
import VideoItem from './VideoItem'
import { removeVideoContent } from './VideoContentController'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

// import { removeShortLink } from './ShortLinksContoller'

export default function VideoContentItem(props) {
    const auth = useSelector(state => state.auth);

    const removeItem = (id) => {
        console.log("Removing Item")
        removeVideoContent(id, auth.accessToken).then(data => {
            console.log(data)
            if (data.deletedCount > 0)
                props.itemRemoved(id)
        }).catch(error => {
            console.log(error)
        })
    }
    const [show, setShow] = useState(false)

    const showVideos = (show) => {
        if (show) {
            return <div className="pt-4">{props.item.videos.map((video, index) => <VideoItem key={video._id} video={video} />)}</div>
        }
    }

    return (
        <li>
            <Card className="p-2 mb-2 d-flex">
                <div className="d-flex flex-row align-items-center justify-content-between mb-3">
                    <div>
                        <Card.Title>Endpoint: {props.item.contentId}</Card.Title>
                        <div>
                            <strong>Description:</strong> {props.item.description}
                        </div>
                    </div>
                    <div className="d-flex">
                        <Button className="mr-3">
                            <Link className="btn btn-primary" to={{ pathname: `/dashboard/videocontents/edit`, content: props.item }} ><PencilIcon size={24} /></Link>


                        </Button>
                        <Button variant="danger" className="" onClick={() => { console.log(props.item._id); removeItem(props.item._id) }}>
                            <TrashIcon size={24}  ></TrashIcon>
                        </Button>
                    </div>
                </div>
                <Button onClick={() => setShow(prev => !prev)}>{show ? "Hide videos" : "Show videos"}</Button>
                <div>

                    {showVideos(show)}

                </div>


            </Card>
            {/* <Card>

            </Card> */}

        </li >
    )
}
