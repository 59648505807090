import React from 'react'

export default function LinkTreeButton(props) {
    return (
        <a key={props.index} href={props.button.btnDestination} className="d-flex justify-content-start align-items-center p-2 w-100 mt-3"
            style={{ backgroundColor: props.button.btnBgColor, border: `1px solid ${props.button.btnBorderColor}`, borderRadius: "5px" }}>
            <div className="d-flex">
                {props.button.btnImageLink ? <div>
                    <img className="mr-2" style={{ maxWidth: "70px", maxHeigth: "700px" }} src={props.button.btnImageLink}></img>
                </div> : <></>}
                <div>
                    <div className="h5 m-0" style={{ color: props.button.btnTitleColor }}>{props.button.btnTitle}</div>
                    <div className="small " style={{ color: props.button.btnDescriptionColor }}>{props.button.btnDescription}</div>
                </div>
            </div>
        </a>
    )
}
