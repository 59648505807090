import React, { useState, useRef, useEffect } from 'react'
import { Alert } from 'react-bootstrap'
export default function EmailCapture() {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")


    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [sending, setSending] = useState(false)
    const form = useRef()

    const handleChange = (event) => {
        console.log(event.target.value)
        switch (event.target.id) {
            case "name":
                setName(event.target.value)
                break;
            case "email":
                setEmail(event.target.value)
                break;
            default:
                break;
        }

    }

    const handleSubmit = (event) => {
        event.preventDefault()
        setSending(true)
        const formData = new FormData(form.current)

        const config = {
            method: 'post',
            body: formData
        }
        fetch('https://mautic.programadorbr.com/form/submit?formId=3', config).then(response => {
            setSending(false)
            return response.text()
        }).then(text => {
            setSuccess(text)
            setSending(false)
            setEmail("")
            setName("")

            console.log(text)
        }).catch(error => {
            // setError(error.message)
            setSuccess("Cadastrado")
            setSending(false)
            setEmail("")
            setName("")
            console.log(error)
        })



    }


    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(false)
            }, 3000);
        }
    }, [error])
    useEffect(() => {
        if (success) {
            setTimeout(() => {
                setSuccess(false)
            }, 3000);
        }
    }, [success])

    return (
        // <!-- Captura-email -->
        <section className="bg-dark-blue">
            <div className="container p-5 text-center">
                <div className="h2 text-light">FIQUE ATUALIZADO</div>
                <p className="text-muted mb-4">Insira aqui o seu email para receber atualizações e conteúdos exclusivos.</p>
                <div className="mw-md m-auto">
                    {/* <!-- Formulários --> */}
                    <form ref={form} onSubmit={handleSubmit}>
                        <div className="form-group text-light">
                            <input required type="text" name="mauticform[nome]" className="form-control" id="name" value={name} placeholder="Coloque seu nome" onChange={handleChange} />
                        </div>
                        <div className="form-group text-light">
                            <input required type="email" name="mauticform[email]" className="form-control" id="email" value={email} placeholder="Coloque seu email" onChange={handleChange} />
                        </div>

                        <button disabled={sending} type="submit" className="btn btn-orange w-100">{sending ? `Enviando...` : `QUERO FICAR ATUALIZADO`}</button>
                        <input type="hidden" name="mauticform[formId]" id="mauticform_progbrleadativo_id" value="3" />
                        <input type="hidden" name="mauticform[return]" id="mauticform_progbrleadativo_return" value="" />
                        <input type="hidden" name="mauticform[formName]" id="mauticform_progbrleadativo_name" value="progbrleadativo" />

                    </form>
                    <Alert show={error} variant="danger">{error}</Alert>
                    <Alert show={success} variant="success">{success}</Alert>
                </div>
            </div>
        </section>
    )
}
