import React from 'react'
import { Card, Button } from 'react-bootstrap'
import { TrashIcon, PencilIcon } from '@primer/octicons-react'

import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import crudController from '../Controller/crudController'

// import { removeShortLink } from './ShortLinksContoller'

export default function Item(props) {
    const auth = useSelector(state => state.auth);
    const controller = crudController(props.module)

    const removeItem = (id) => {
        controller.remove(id, auth.accessToken).then(data => {
            if (data.deletedCount > 0)
                props.itemRemoved(id)
        }).catch(error => {
            console.log(error)
        })
    }

    return (
        <li>
            <Card className="p-2 mb-2 d-flex">
                <div className="d-flex flex-row align-items-center justify-content-between mb-3">
                    <div>

                        {props.showOnList.map((field) => {
                            return <div><strong>{field}:</strong> {props.item[field]}</div>
                        })}

                    </div>
                    <div className="d-flex">
                        <Button className="mr-3">
                            <Link className="btn btn-primary" to={{ pathname: `/dashboard/${props.module}/edit`, content: props.item }} ><PencilIcon size={24} /></Link>
                        </Button>
                        <Button variant="danger" className="" onClick={() => { console.log(props.item._id); removeItem(props.item._id) }}>
                            <TrashIcon size={24}  ></TrashIcon>
                        </Button>
                    </div>
                </div>
            </Card>
        </li >
    )
}
