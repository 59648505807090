import React, { Fragment, useState, useEffect } from 'react'
import Button from '../common/Button'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import Container from '../Container'
import BackgroundDark from '../common/BackgroundDark'
import StyledAnchor from '../common/StyledAnchor'

const Image = styled.img`
 max-width: 100%;
  `
function certifitatePathBack(cert) {
    return `/api/certificate/${cert}`
}

function CertificateView(props) {

    const params = useParams();

    const [mediaLink, setMediaLink] = useState();

    useEffect(() => {
        fetch(certifitatePathBack(params.id))
            .then(res => res.json())
            .then(data => {
                setMediaLink(data.mediaLink)
            })
    }, [])

    return (
        <BackgroundDark>
            <Container>
                <Image src={mediaLink} ></Image>
                <StyledAnchor download href={mediaLink}><Button color="blue" mt>Download</Button></StyledAnchor>
            </Container>
        </BackgroundDark>
    )


}

export default CertificateView