import React, { useState } from 'react'
import Placeholder from '../assets/placeholder.png'
import FilterGroup from './Filter/FilterGroup'

export default function ImageFilter(props) {
    const [filterStyle, setFilterstyle] = useState("")

    const updateFilter = (filterString) => {
        setFilterstyle(filterString)
    }

    const removeFilter = (event) => {
        const image = event.target
        image.style.filter ? image.style.filter = "" : image.style.filter = filterStyle
    }

    return (
        <div className="d-flex flex-column align-items-center">
            <img style={{ filter: filterStyle }} className="image mb-3" alt="" src={props.fileSrc || Placeholder} onClick={removeFilter}></img>
            <div className="w-50 d-flex justify-content-center align-items-center flex-column">
                {props.fileSrc ? <FilterGroup updateFilter={updateFilter} ></FilterGroup> : <></>}
            </div>
        </div >
    )
}
