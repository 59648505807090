import React, { Fragment } from 'react'
import styled from 'styled-components'

// import {
//     mainFont
// } from './CssDefaultAttributes'
import SimpleInput from './SimpleInput'
import Label from './Label'


const InputMessage = styled.div`
/* Normal Text - Eurostile Regular - 16px (25px) */

font-family: Eurostile;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 25px;
`

export default function Input(props) {

    const message = () => {
        if (props.message) {
            return <InputMessage>{props.message}</InputMessage>
        }
    }

    return (
        <Fragment>
            <Label for={props.id}>{props.title}</Label>
            <SimpleInput id={props.id} type={props.type} onChange={props.onChange} placeholder={props.placeholder}></SimpleInput>
            {message()}
        </Fragment>
    )


}