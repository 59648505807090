import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'


import Margins from './Margins'

import {
  getColorGradient,
  getFontColor,
  getShadowColor,
  mainFont
} from './CssDefaultAttributes'


const Button = styled.button`
  font-family: ${mainFont};
  font-style: normal;
  font-weight: 700;
  font-size: 18px;

  // height:65px;
  padding: 20px 30px;

  text-decoration: none;

  display: flex;
  position: relative;
  flex-grow:1;
  align-items: center;
  text-align: center;
  justify-content:center;
  width:100%;
  text-transform: uppercase;
  background:${getColorGradient()};
  border-radius: 6px;
  border:none;
  color:white;

  transition: all .2s;



  ${props =>
    props.color &&
    css`
      background: ${getColorGradient(props.color)};
      color: ${getFontColor(props.color)};
      &:hover{

      box-shadow: 0 20px 80px 0 ${getShadowColor(props.color)};
      }
    `};

    ${props =>
    props.noshadow &&
    css`
    &:hover{
      box-shadow: none;
    }
        `};

    ${props =>
    props.small &&
    css`
    font-size: 14px;
    padding: 10px 10px;
      `};

      ${props =>
    props.animated &&
    css`
        &:hover{
          font-size: 20px;
          animation: pulse 0.9s infinite;
          animation-timing-function: linear;   
      
        }
        @keyframes pulse {
          0% { transform: scale(1); }
          50% { transform: scale(1.1);}
          100% { transform: scale(1); }
          
          }
        `};

    

          ${props =>
    props.outline &&
    css`
    color: #000;
    background: #FFF;
    background-clip: padding-box; /* !importanté */
    border: solid 5px transparent; /* !importanté */
    border-radius: 5px;
    text-decoration: none;
    &:before {
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -3px; /* !importanté */
      border-radius: inherit; /* !importanté */
      background: ${getColorGradient(props.color)};
    }
          `};

          ${Margins.mt}
          ${Margins.mb}

`

function StyledButton(props) {
  return (<Button {...props} />)
}

export default StyledButton