import React, { useRef, useEffect, useState } from 'react'
import Share from './Share'
// import YoutubePlayer from 'react-youtube-player'
import Vimeo from '@u-wave/react-vimeo';

import ReactPixel from 'react-facebook-pixel';
import CallToAction from './CallToAction';
import Banner from '../Banner';

export default function VimeoPlayerVideo(props) {

    // const youtubePlayer = useRef()
    // // const [myTimer, setMyTimer] = useState()
    // const [played, setPlayed] = useState(false)
    // useEffect(() => {
    //     console.log("iFRAME", youtubePlayer.current)

    //     // console.log(youtubePlayer.current.getCurrentTime())
    // }, [youtubePlayer])

    // useEffect(() => {
    //     return () => {
    //         console.log("UNMONTED")
    //         checkProportion()
    //     }
    // }, [])

    // const markPixel = (value) => {
    //     ReactPixel.trackCustom(`watched_${props.video.videoId}_${value}`, { value });
    //     ReactPixel.trackCustom(`watched_${props.group}_${value}`, { value });
    //     ReactPixel.trackCustom(`watched_any_${value}`, { value });
    // }


    // const checkProportion = async () => {
    //     const currentTime = await youtubePlayer.current.player.getCurrentTime()
    //     const duration = await youtubePlayer.current.player.getDuration()
    //     if (currentTime == undefined || duration == undefined)
    //         return
    //     const proportion = currentTime / duration
    //     if (proportion > 0.25 && proportion < 0.5) {
    //         markPixel(25)
    //     }

    //     if (proportion > 0.5 && proportion < 0.75) {
    //         markPixel(50)
    //     }

    //     if (proportion > 0.75) {
    //         markPixel(75)
    //     }
    // }

    // const handlePlay = (event) => {
    //     console.log("PLAY", event)
    //     if (!played)
    //         ReactPixel.trackCustom(`watched_${props.video.videoId}`);
    //     setPlayed(true)
    // }
    // const handlePause = async (event) => {
    //     checkProportion()
    //     console.log("PAUSE")
    //     // if(youtubePlayer.current.player)
    //     // ReactPixel.trackCustom(`watched_${props.video.videoId}`, { all: true });

    // }
    // const handleEnd = (event) => {
    //     console.log("STOP", event)
    //     markPixel(100)
    // }
    return (
        // <!-- Main Video -->
        <section>
            <div className="container py-md-4">
                <div className="d-flex flex-row my-3">
                    <div className="embed-responsive embed-responsive-16by9">
                        {/* <YoutubePlayer ></YoutubePlayer> */}
                        {/* <iframe ref={frame} className="embed-responsive-item" max-width="820"
                            src={`https://www.youtube.com/embed/${props.video.videoId}?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0`}
                            frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe> */}

                        <iframe src={`https://player.vimeo.com/video/${props.video.videoId}`} width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>

                        {/* 
                        <Vimeo
                            // configuration={{
                            //     showinfo: "0",
                            //     modestbranding: "1",
                            //     rel: "0"
                            // }}
                            // ref={youtubePlayer}
                            // onPlay={handlePlay}
                            // onPause={handlePause}
                            // onEnd={handleEnd}
                            // className="embed-responsive-item"
                            video={457155004}
                            autoplay
                        ></Vimeo> */}
                    </div>
                    {/* <a className="ml-3 d-none d-lg-flex" href={`https://www.programadorbr.com/?src=banner_${props.group}`} target="_blank"><img
                        className="mw-100" src="/assets/content/images/banner_half_page_with_text_blue_photo1.png" /></a> */}
                    <Banner vertical></Banner>
                </div>
                {/* <!-- <h4>Github ou Próprio site. Qual a melhor forma de
                    mostrar o portfólio para você? 
                </h4>--> */}
                <h4>{props.video.title}</h4>
                <div className="d-flex align-sm-items-center flex-column flex-sm-row ">
                    <Share className="mr-sm-2 mb-2 mb-sm-0"></Share>
                    <CallToAction className="" video={props.video}></CallToAction>
                </div>


                {/* <!-- Banner -->
                <a href={`https://www.programadorbr.com/?src=banner_${props.group}`} target="_blank"> <img className="mw-100 d-md-none mt-5 d-flex"
                    src="/assets/content/images/banner_small_rectangle_blue.png" /></a>
                <a href={`https://www.programadorbr.com/?src=banner_${props.group}`} target="_blank"><img className="mw-100 mt-5 d-none d-md-flex d-lg-none"
                    src="/assets/content/images/banner_medium_leaderboard_blue.png" /></a> */}
                <Banner ></Banner>
            </div>
        </section >
    )
}
