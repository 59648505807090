import tokenManager from '../../components/Login/tokenManager'

import {
    REGISTER_FAIL,
    REGISTER_SUCCESS,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    AUTH_ERROR
} from '../actions/types'

const initialState = {
    accessToken: tokenManager.getAccessToken(),
    refreshToken: tokenManager.getRefreshToken(),
    isAuthenticated: null,
    loading: true,
    user: null
}


export default (state = initialState, action) => {
    // console.log('Reducer:', state, action)

    switch (action.type) {
        case LOGIN_SUCCESS:
        case REGISTER_SUCCESS:
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true,
                loading: false
            }
        case REGISTER_FAIL:
        case AUTH_ERROR:
        case LOGIN_FAIL:
            return {
                ...state,
                ...action.payload,
                user: null,
                accessToken: null,
                refreshToken: null,
                isAuthenticated: false,
                loading: false
            }


        default:
            return state;
    }
}