import React from 'react'

export default function Footer() {
    return (

        // <!-- Footer -->
        <section className="bg-dark-blue  footer">
            <footer className="container navbar navbar-dark py-2 justify-content-center text-center justify-content-md-between">
                {/* <!-- Footer-links --> */}
                <ul className="d-flex flex-row navbar-nav">
                    <li className="nav-item text-sm"><a href="https://www.programadorbr.com/politicas.html"
                        className="nav-link">Termos
                        de Uso e Política de Privacidade</a></li>
                </ul>
                {/* <!-- Footer-Copywrite --> */}
                <div className="text-muted text-sm">Programador Br © 2019 - Todos os
                direitos reservados</div>
            </footer>
        </section>

    )
}
