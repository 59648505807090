import React from 'react'
import LinkTreeItem from './LinkTreeItem';

export default function LinkTreeList(props) {

    return (
        <ul className="list-unstyled">{
            props.linkTrees ?
                props.linkTrees.map((item, index) =>
                    // <VideoContentItem itemRemoved={} key={index} item={item} />
                    <LinkTreeItem itemRemoved={props.itemRemoved} key={index} item={item} />
                    // <div>a</div>
                ) : <></>}</ul>
    )
}
