
const getLinkTrees = () => {
    return new Promise((resolve, reject) => {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        var config = {
            method: 'POST',
            headers: headers,
        };
        fetch('/linktree/all', config).then(async response => {
            if (response.status >= 400) {
                reject("Unauthorized")
            }
            console.log(response)
            return response.json()
        }
        ).then(json => {
            resolve(json)
        }).catch(error => {
            console.log(error)
            reject(error)
        })

    })
}

const getLinktreeForEndpoint = (endpoint) => {
    return new Promise((resolve, reject) => {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        var config = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ key: "endpoint", value: endpoint })
        };
        console.log("/linktree/get")
        fetch('/linktree/get', config).then(async response => {
            console.log("/linktree/get")
            console.log(response)

            if (response.status >= 400) {
                reject("Unauthorized")
                return
            }
            return response.json()
        }
        ).then(json => {
            console.log("JSON", json)
            resolve(json)
        }).catch(error => {
            console.log(error)
            reject(error)
        })

    })
}



const addLinkTree = (accessToken, linktree) => {

    return new Promise((resolve, reject) => {

        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("x-auth-token", accessToken);
        console.log(linktree)
        const body = JSON.stringify(linktree)

        var config = {
            method: 'POST',
            headers: headers,
            body
        };
        fetch('/linktree/add', config).then(async response => {
            return response.json()
        }
        ).then(json => {
            resolve(json)
        }).catch(error => {
            console.log(error)
            reject(error)
        })

    })
}

const updateLinkTree = (accessToken, _id, linktree) => {

    return new Promise((resolve, reject) => {

        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("x-auth-token", accessToken);

        const body = JSON.stringify({ _id, ...linktree })

        var config = {
            method: 'POST',
            headers: headers,
            body
        };
        fetch('/linktree/update', config).then(async response => {
            return response.json()
        }
        ).then(json => {
            resolve(json)
        }).catch(error => {
            console.log(error)
            reject(error)
        })

    })
}

const removeLinktree = (_id, accessToken) => {
    console.log("Remove", _id)
    return new Promise((resolve, reject) => {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("x-auth-token", accessToken);

        const body = JSON.stringify({ _id })

        var config = {
            method: 'POST',
            headers: headers,
            body
        };
        fetch('/linktree/remove', config).then(async response => {
            if (response.status > 400) {
                throw response
            }
            return response.json()
        }
        ).then(json => {
            resolve(json)
        }).catch(error => {
            console.log(error)
            reject(error)
        })

    })
}

export { getLinkTrees, getLinktreeForEndpoint, addLinkTree, updateLinkTree, removeLinktree }