import React, { Fragment } from 'react'
import Banner from '../../Banner'
import Popup from '../../Popup/Popup'
export default function GameOver(props) {
    return (props.show ?
        <div id="gameOver">
            <div className="text-center">
                Parabéns, você completou o jogo!
            </div>
            <div className="container">
                <div className="h3 text-light text-center"> Aprenda todas essas tecnologias no nosso curso. </div>
                <Banner src="mem_gameover"></Banner>
                {/* <Popup></Popup> */}
            </div>

            <button id="restart" onClick={props.handleRestart} >Jogue novamente</button>
        </div> : <Fragment />
    )
}
