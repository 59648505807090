import React, { useState, useEffect, Fragment } from 'react'
import { getLinktreeForEndpoint } from '../Dashboard/LinkTrees/LinkTreeController'
import { Link, useLocation } from 'react-router-dom'

import ReactPixel from 'react-facebook-pixel';
import './linkTree.css';
import LinkTreeButton from './LinkTreeButton';

export default function LinkTreeTemplate() {
    const location = useLocation()
    const args = location.pathname.split('/')
    const [linkTree, setLinkTree] = useState()
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {

        console.log(args[2])

        if (!loaded)
            getLinktreeForEndpoint(args[2]).then(content => {
                console.log("content")
                setLoaded(true)
                setLinkTree(content)
                console.log(content)
                // ReactPixel.trackCustom(`content_${args[2]}`);

            }).catch(error => {
                // setUnauthorized(true)
                console.log(error)
            })
    }, [loaded])

    useEffect(() => {

        setLoaded(false)
        console.log("CHANGE SELECCTED")
    }, [location])

    useEffect(() => {
        if (linkTree) {
            ReactPixel.trackCustom(`LinkTree_${args[2]}`);
            document.querySelector("body").style.backgroundColor = linkTree.pageBgColor

        }
    }, [linkTree])

    function Buttons(props) {

        return (
            props.buttons ? props.buttons.map((button, index) =>
                <LinkTreeButton key={index} button={button} index={index} />
            ) : <></>
            // <div>{JSON.stringify(props.buttons)}</div>
        )
    }

    return (
        linkTree ?
            <div className={`d-flex pt-3 align-items-center flex-column bg`} style={{ backgroundColor: linkTree.pageBgColor, minHeight: "100%" }}>
                {/* <div> {JSON.stringify(linkTree)}</div> */}

                <div id="linktree" className=" d-flex justify-content-center flex-column w-100 align-items-center p-3 " style={{ maxWidth: "420px" }}>
                    {linkTree.pageImageLink ? <img src={linkTree.pageImageLink} style={{ maxWidth: "70px", maxHeigth: "70px" }}></img> : <></>}
                    {linkTree.pageTitle ? <div className="mt-2" style={{ color: linkTree.pageTitleColor }}>{linkTree.pageTitle}</div> : <></>}
                    <Buttons buttons={linkTree.buttons}></Buttons>
                </div>

            </div> : <></>
    )
}
