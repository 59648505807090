import React from 'react'

export default function VideoItem(props) {
    return (
        <div className="border-bottom mb-3">
            <div>{props.video.title}</div>
            <div>{props.video.platform}</div>
            <div>{props.video.videoId}</div>
        </div>
    )
}
