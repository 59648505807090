import React, { useState, useEffect } from 'react'
import { Button, Alert } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { addVideoContent, updateVideoContent } from '../VideoContentController'
import VideoCard from './VideoCard'
import { useSelector } from 'react-redux'

export default function VideoContentAdd(props) {
    const history = useHistory()
    const auth = useSelector(state => state.auth);


    const [contentId, setContentId] = useState("")
    const [description, setDescription] = useState("")
    const [videos, setVideos] = useState([createNewEmptyVideo()])

    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        console.log(props)
        if (props.location && props.location.content) {
            const content = props.location.content
            console.log(content)
            setContentId(content.contentId)
            setDescription(content.description)
            setVideos(content.videos)
        }
    }, [props])

    function createNewEmptyVideo() {
        return {
            videoId: "",
            title: "",
            thumbnailUrl: "",
            platform: "",
            callToActionOn: false,
            callToActionTitle: "",
            callToActionLink: "",
            unlockDate: ""
        }

    }
    function removeVideo(index) {
        setVideos(videos.filter((video, idx) => index !== idx))
    }

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(false)
            }, 3000);
        }
    }, [error])

    function cancel() {
        history.goBack()
    }

    function save() {
        let error = false
        if (contentId.replace(/\s+/g, '') === "" || description.replace(/\s+/g, '') === "" || videos.length === 0) {
            error = true;
            setError("Verifique o Endpoint e a Description")
            return
        }
        videos.every((video, index) => {
            if (video.videoId.replace(/\s+/g, '') === "" ||
                video.title.replace(/\s+/g, '') === "" ||
                // video.thumbnailUrl.replace(/\s+/g, '') === "" ||
                video.platform.replace(/\s+/g, '') === "" ||
                video.unlockDate.replace(/\s+/g, '') === "") {
                setError(`Verifique os dados no video ${index}`)
                error = true;

                return false
            }
            return true
        })

        function handlePromise(promise) {
            promise.then(response => {
                console.log(response)
                if (response.status > 400) {
                    setError("Não foi Possivel salvar")
                } else {
                    if (response.nModified || response._id) {
                        setSuccess("Content Group salvo com sucesso")
                        setTimeout(() => {
                            history.goBack()
                        }, 1000);
                    } else {
                        setError("Ocorreu um erro na hora de salvar")
                    }
                }
            }).catch(error => {
                console.log(error)
                setError("Não foi Possivel salvar")
            })
        }
        if (!error) {
            if (props.location && props.location.content) {
                handlePromise(updateVideoContent(auth.accessToken, props.location.content._id, contentId, description, videos))

            } else {
                handlePromise(addVideoContent(auth.accessToken, contentId, description, videos))

            }
        }

    }

    const VideoCards = ({ videosData, updateVideoData, removeVideo }) => {


        console.log("VIDEO", videos)
        return videos.map((item, index) => <VideoCard removeVideo={removeVideo} key={index} videoValue={item} index={index} updateVideoData={updateVideoData}></VideoCard>)

    }

    const updateVideoData = (index, key, value) => {
        console.log("UPDATE VIDEO DATA", index, key, value)
        setVideos(prev => {
            prev[index][key] = value
            return prev
        })
    }

    useEffect(() => {
        console.log(videos)
    }, [videos])




    return (
        <div>
            <header className="d-flex justify-content-end">
                <Button onClick={cancel} variant="danger" className="mr-2  ">
                    Cancel
                </Button>
                <Button onClick={save}>
                    Save
                </Button>
            </header>

            <form className="d-flex flex-column mt-4">
                <input onChange={element => { setContentId(element.target.value) }} className="mb-3" name="contentId" id="contentId" placeholder="Endpoint" value={contentId}></input>
                <input onChange={element => { setDescription(element.target.value) }} className="mb-3" name="description" id="description" placeholder="Description" value={description}></input>


            </form>
            <Button className="mr-2" onClick={() => {
                setVideos(prev => {
                    const copy = [createNewEmptyVideo(), ...prev]
                    return copy;
                })
            }}>+ First</Button>
            <Button onClick={() => {
                setVideos(prev => {
                    const copy = [...prev, createNewEmptyVideo()]
                    return copy;
                })
            }}>+ Last</Button>
            <VideoCards removeVideo={removeVideo} updateVideoData={updateVideoData} videosData={videos}></VideoCards>
            <Alert show={error} variant="danger">{error}</Alert>
            <Alert show={success} variant="success">{success}</Alert>
        </div >
    )
}
