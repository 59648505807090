import React, { useState, useEffect } from 'react'
import { Card, Button } from 'react-bootstrap'
import { dateToInputDate } from '../../../common/DateHelper'
import { TrashIcon } from '@primer/octicons-react'

export default function VideoCard({ videoValue, index, updateVideoData, removeVideo }) {

    const [videoId, setVideoId] = useState(videoValue.videoId || "")
    const [title, setTitle] = useState(videoValue.title || "")
    const [thumbnailUrl, setThumbnailUrl] = useState(videoValue.thumbnailUrl || "")
    const [platform, setPlatform] = useState(videoValue.platform || "")
    const [callToActionOn, setCallToActionOn] = useState(videoValue.callToActionOn || false)
    const [callToActionTitle, setCallToActionTitle] = useState(videoValue.callToActionTitle || "")
    const [callToActionLink, setCallToActionLink] = useState(videoValue.callToActionLink || "")

    const [unlockDate, setUnlockDate] = useState(videoValue.unlockDate || dateToInputDate(new Date(Date.now())))


    // function formatNumber(number) {
    //     return number < 10 ? `0${number}` : `${number}`
    // }

    // function dateToInputDate(date) {
    //     const year = date.getUTCFullYear();
    //     const month = date.getUTCMonth() + 1
    //     const day = date.getUTCDate()
    //     const hour = date.getUTCHours()
    //     const minutes = date.getUTCMinutes()
    //     const time = `${year}-${formatNumber(month)}-${formatNumber(day)}T${formatNumber(hour)}:${formatNumber(minutes)}`
    //     return time
    // }

    useEffect(() => {
        console.log(unlockDate)
    }, [])

    useEffect(() => {
        updateVideoData(i, "videoId", videoId)
        updateVideoData(i, "title", title)
        updateVideoData(i, "thumbnailUrl", thumbnailUrl)
        updateVideoData(i, "platform", platform)
        updateVideoData(i, "callToActionOn", callToActionOn)
        updateVideoData(i, "callToActionTitle", callToActionTitle)
        updateVideoData(i, "callToActionLink", callToActionLink)
        updateVideoData(i, "unlockDate", unlockDate)

    }, [videoId, title, platform, thumbnailUrl, unlockDate, callToActionOn, callToActionTitle, callToActionLink])

    const i = index
    return <Card key={i} className="p-2 mb-2" >
        <div className="d-flex justify-content-between p-2 align-items-center">
            <h4>{i}</h4>
            <Button variant="outline-danger" onClick={() => { removeVideo(i) }}>
                <TrashIcon size={24}  ></TrashIcon>
            </Button>
        </div>
        <input onChange={(element) => { setVideoId(element.target.value) }}
            // onBlur={element => { updateVideoData(i, "videoId", element.target.value) }}
            className="mb-2"
            name={`videoId${i}`}
            id={`videoId${i}`}
            placeholder="Video Id"
            value={videoId}>
        </input>

        <input onChange={(element) => { setTitle(element.target.value) }}
            // onBlur={element => { updateVideoData(i, "title", element.target.value) }}
            className="mb-3"
            name={`title${i}`}
            id={`title${i}`}
            placeholder="Video Title"
            value={title}>
        </input>


        <input onChange={(element) => { setThumbnailUrl(element.target.value) }}
            className="mb-3"
            name={`thumbnailUrl${i}`}
            id={`thumbnailUrl${i}`}
            placeholder="Thumbnail Url"
            value={thumbnailUrl}>
        </input>

        <div>
            <input className="mr-2"
                onChange={(element) => {
                    setPlatform(element.target.value)
                    console.log(element.target.value)
                    console.log(platform)
                }}
                // onBlur={element => { updateVideoData(i, "platform", element.target.value) }}
                type="radio" id={`platformYT${i}`}
                name={`platformYT${i}`} value="youtube"
                checked={platform == "youtube" ? true : false}></input>
            <label htmlFor="youtube">Youtube</label>
        </div>

        <div>
            <input className="mr-2"
                onChange={(element) => {
                    setPlatform(element.target.value)
                    console.log(element.target.value)
                }}
                // onBlur={element => { updateVideoData(i, "platform", element.target.value) }}
                type="radio" id={`platformVM${i}`}
                name={`platformVM${i}`}
                value="vimeo"
                checked={platform == "vimeo" ? true : false}></input>
            <label htmlFor="vimeo">Vimeo</label>
        </div>
        <div>
            <input className="mr-2"
                onChange={(element) => {
                    setCallToActionOn(element.target.checked)
                    console.log(element.target.value)
                }}
                // onBlur={element => { updateVideoData(i, "platform", element.target.value) }}
                type="checkbox" id={`callToActionOn${i}`}
                name={`callToActionOn${i}`}
                value={callToActionOn}
                checked={callToActionOn}></input>
            <label htmlFor={`callToActionOn${i}`}>Call To Action</label>
        </div>

        {callToActionOn ?
            <div className="d-flex flex-column">
                <input onChange={(element) => { setCallToActionTitle(element.target.value) }}
                    className="mb-3"
                    name={`callToActionTitle${i}`}
                    id={`callToActionTitle${i}`}
                    placeholder="Call To Action Title"
                    value={callToActionTitle}>
                </input>
                <input onChange={(element) => { setCallToActionLink(element.target.value) }}
                    className="mb-3"
                    name={`callToActionLink${i}`}
                    id={`callToActionLink${i}`}
                    placeholder="Call To Action Link"
                    value={callToActionLink}>
                </input>

            </div> : <></>}





        <label htmlFor={`unlockDate${i}`}>Unlock Date: (UTC Time)</label>
        <input className=""
            // onBlur={element => { updateVideoData(i, "unlockDate", element.target.value) }}
            onChange={(element) => {
                console.log(element.target.value)
                setUnlockDate(element.target.value)
            }}
            name={`unlockDate${i}`}
            id={`unlockDate${i}`}
            type="datetime-local"
            value={unlockDate}
        ></input>
    </Card>
}
