
const getShortLinks = (accessToken) => {
    console.log("SHORT LINKS ACCESS", accessToken)
    return new Promise((resolve, reject) => {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("x-auth-token", accessToken);

        var config = {
            method: 'POST',
            headers: headers,
        };
        fetch('/short/list', config).then(async response => {
            if (response.status >= 400) {
                reject("Unauthorized")

            }
            return response.json()
        }
        ).then(json => {
            resolve(json)
        }).catch(error => {
            console.log(error)
            reject(error)
        })

    })
}

const addShortLink = (endpoint, destination, view) => {
    return new Promise((resolve, reject) => {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        const body = JSON.stringify({ endpoint, destination, view })

        var config = {
            method: 'POST',
            headers: headers,
            body
        };
        fetch('/short/add', config).then(async response => {

            return response.json()
        }
        ).then(json => {
            resolve(json)
        }).catch(error => {
            console.log(error)
            reject(error)
        })

    })
}

const removeShortLink = (id) => {
    console.log("Remove", id)
    return new Promise((resolve, reject) => {
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        const body = JSON.stringify({ id })

        var config = {
            method: 'POST',
            headers: headers,
            body
        };
        fetch('/short/remove', config).then(async response => {

            return response.json()
        }
        ).then(json => {
            resolve(json)
        }).catch(error => {
            console.log(error)
            reject(error)
        })

    })
}

export { getShortLinks, addShortLink, removeShortLink }