import React, { useState, useEffect } from 'react'

export default function ButtonCard(props) {

    const [btnBgColor, setBtnBgColor] = useState(props.buttonValue.btnBgColor || "#ffffff")
    const [btnBorderColor, setBtnBorderColor] = useState(props.buttonValue.btnBorderColor || "#000000")

    const [btnTitleColor, setBtnTitleColor] = useState(props.buttonValue.btnTitleColor || "#333333")
    const [btnDescriptionColor, setBtnDescriptionColor] = useState(props.buttonValue.btnDescriptionColor || "#333333")

    const [btnTitle, setBtnTitle] = useState(props.buttonValue.btnTitle || "")
    const [btnDescription, setBtnDescription] = useState(props.buttonValue.btnDescription || "")
    const [btnDestination, setBtnDestination] = useState(props.buttonValue.btnDestination || "")
    const [btnImageLink, setBtnImageLink] = useState(props.buttonValue.btnImageLink || "")
    const i = props.index

    useEffect(() => {

    })

    useEffect(() => {
        props.updateVideoData(i, "btnBgColor", btnBgColor)
        props.updateVideoData(i, "btnBorderColor", btnBorderColor)

        props.updateVideoData(i, "btnTitleColor", btnTitleColor)
        props.updateVideoData(i, "btnDescriptionColor", btnDescriptionColor)
        props.updateVideoData(i, "btnDestination", btnDestination)

        props.updateVideoData(i, "btnTitle", btnTitle)
        props.updateVideoData(i, "btnDescription", btnDescription)
        props.updateVideoData(i, "btnImageLink", btnImageLink)


    }, [btnBgColor, btnTitleColor, btnDescriptionColor, btnTitle, btnDescription, btnImageLink, btnDestination, btnBorderColor])



    return (
        <div className="card p-2 mt-4">
            <input onChange={element => { setBtnTitle(element.target.value) }} className="mb-3" name={`btnTitle${i}`} id={`btnTitle${i}`} placeholder="Title" value={btnTitle}></input>
            <input onChange={element => { setBtnDestination(element.target.value) }} className="mb-3" name={`btnDestination${i}`} id={`btnDestination${i}`} placeholder="Destination" value={btnDestination}></input>

            <input onChange={element => { setBtnDescription(element.target.value) }} className="mb-3" name={`btnDescription${i}`} id={`btnDescription${i}`} placeholder="Description" value={btnDescription}></input>

            <input onChange={element => { setBtnImageLink(element.target.value) }} className="mb-3" name={`btnImageLink${i}`} id={`btnImageLink${i}`} placeholder="Image Link" value={btnImageLink}></input>
            <div className="d-flex justify-content-between">
                <label for={`btnBgColor${i}`} >Btn Bg Color </label>
                <input type="color" onChange={element => { setBtnBgColor(element.target.value) }} className="mb-3" name={`btnBgColor${i}`} id={`btnBgColor${i}`} placeholder="Button Bg Color" value={btnBgColor}></input>
            </div>
            <div className="d-flex justify-content-between">
                <label for={`btnBorderColor${i}`} >Btn Border Color </label>
                <input type="color" onChange={element => { setBtnBorderColor(element.target.value) }} className="mb-3" name={`btnBorderColor${i}`} id={`btnBorderColor${i}`} placeholder="Button Border Color" value={btnBorderColor}></input>
            </div>
            <div className="d-flex justify-content-between">
                <label for={`btnTitleColor${i}`}>Btn Title Color </label>
                <input type="color" onChange={element => { setBtnTitleColor(element.target.value) }} className="mb-3" name={`btnTitleColor${i}`} id={`btnTitleColor${i}`} placeholder="Title Color" value={btnTitleColor}></input>
            </div>
            <div className="d-flex justify-content-between">
                <label for={`btnDescriptionColor${i}`}>Btn Description Color </label>
                <input type="color" onChange={element => { setBtnDescriptionColor(element.target.value) }} className="mb-3" name={`btnDescriptionColor${i}`} id={`btnDescriptionColor${i}`} placeholder="Text Color" value={btnDescriptionColor}></input>
            </div>
        </div>
    )
}
