import React, { useEffect, useState, useContext } from "react"
import { EnglishTrainerContext } from "./EnglishTrainerProvider"
import { CheckCircleIcon, XCircleIcon } from '@primer/octicons-react'

export default function Transcriber(props) {
    // const { listenedSentence, actualSentence } = useContext(EnglishTrainerContext)
    const [match, setMach] = useState(false)

    function checkMatch(actual, listened) {
        var punctuation = '!"#$%&()*+,-./:;<=>?@[\\]^_`{|}~';
        function removePunctuation(string) {
            return string.toLowerCase()
                .split('')
                .filter(function (letter) {
                    return punctuation.indexOf(letter) === -1;
                })
                .join('');
        }
        console.log(removePunctuation(actual), removePunctuation(listened))
        if (removePunctuation(listened) === removePunctuation(actual)) {
            setMach(true)
        } else {
            setMach(false)
        }
    }

    useEffect(() => {
        // console.log(match)
    }, [match])

    useEffect(() => {
        // console.log(props.listenedSentence)
        if (props.listenedSentence === "Listening..." || props.listenedSentence === "Ready To Listen") {
            setMach(null)
            return
        }
        checkMatch(props.actualSentence, props.listenedSentence)
    }, [props.listenedSentence, props.actualSentence])
    return (
        <div className={`${match != null ? match ? "text-success" : "text-danger" : "font-italic"} d-flex `}>
            { props.listenedSentence != " " && props.listenedSentence != "Listening..." ? match ? <CheckCircleIcon className="align-self-center mr-1" /> : <XCircleIcon className="align-self-center mr-1" /> : <></>}

            { props.listenedSentence[0].toUpperCase() + props.listenedSentence.substring(1)}

        </div>
    )
}
