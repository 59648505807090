import React from 'react'


// import BannersImages from '../assets/banner/BannersImages'
import BannersImages from '../assets/banners/BannersImages'

export default function Banner(props) {
    return (
        // <!-- Banner -->
        props.vertical ?
            // <a className="ml-3 d-none d-lg-flex" href={`https://www.programadorbr.com/?src=banner_${props.group}`} target="_blank"><img
            //     className="mw-100" src="/assets/content/images/banner_half_page_with_text_blue_photo1.png" /></a>
            <a className="ml-3 d-none d-lg-flex" href={`https://www.programadorbr.com/?src=banner_${props.group}`} target="_blank"><img
                className="mw-100" src={BannersImages.Banner_300x600} /></a>
            :
            <section>
                <div className="container pt-5 pb-5">
                    {/* <a href={`https://www.programadorbr.com/?src=banner${props.src}`} rel="noopener noreferrer" target="_blank"><img className="mw-100 d-none d-lg-flex"
                        src="/assets/content/images/banner_large_leaderboard_orange_photo1.png" alt="Banner" /></a>
                    <a href={`https://www.programadorbr.com/?src=banner${props.src}`} target="_blank" rel="noopener noreferrer"><img className="mw-100 d-none d-md-flex d-lg-none"
                        src="/assets/content/images/banner_medium_leaderboard_blue.png" alt="Banner" /></a>
                    <a href={`https://www.programadorbr.com/?src=banner${props.src}`} target="_blank" rel="noopener noreferrer"><img className="mw-100 d-md-none d-flex"
                        src="/assets/content/images/banner_medium_rectangle_blue_photo1.png" alt="Banner" /></a> */}
                    <a href={`https://www.programadorbr.com/?src=banner${props.src}`} rel="noopener noreferrer" target="_blank"><img className="mw-100 d-none d-lg-flex"
                        src={BannersImages.Banner_1160x130} alt="Banner" /></a>
                    <a href={`https://www.programadorbr.com/?src=banner${props.src}`} target="_blank" rel="noopener noreferrer"><img className="mw-100 d-none d-md-flex d-lg-none"
                        src={BannersImages.Banner_728x90} alt="Banner" /></a>
                    <a href={`https://www.programadorbr.com/?src=banner${props.src}`} target="_blank" rel="noopener noreferrer"><img className="mw-100 d-md-none d-flex"
                        src={BannersImages.Banner_580x278} alt="Banner" /></a>
                </div>
            </section>
    )
}
