import React, { useEffect, useRef, useState, useContext } from "react";
import { EnglishTrainerContext } from "./EnglishTrainerProvider";
const synth = window.speechSynthesis;

export default function AccentSelector(props) {
    const voiceSelect = useRef()
    const rateSelect = useRef()
    const [voices, setVoices] = useState([]);
    const { selectedVoice, setSelectedVoice, speechRate, setSpeechRate } = useContext(EnglishTrainerContext)
    const excludedVoices = ["Victoria", "Fred", "Alex"]
    const speedRates = [1.0, 0.9, 0.8, 0.7, 0.6, 0.5]

    useEffect(() => {
        function populateVoiceList() {
            // const newvoices = synth.getVoices().filter(voice => voice.lang.includes("en") && !excludedVoices.includes(voice.name)).sort(function (a, b) {
            const newvoices = synth.getVoices().filter(voice => voice.lang.includes("en") && voice.name.includes("Google")).sort(function (a, b) {
                const aname = a.name.toUpperCase(), bname = b.name.toUpperCase();
                if (aname < bname) return +1;
                else if (aname == bname) return 0;
                else return -1;
            })
            synth.getVoices()
            console.log(newvoices)
            return newvoices
        }
        setTimeout(() => {
            setVoices(populateVoiceList())
        }, 15);
        console.log(selectedVoice)

        const rs = rateSelect.current
        var selectedIndex = rs.selectedIndex < 0 ? 0 : rs.selectedIndex;
        rs.innerHTML = '';
        for (let i = 0; i < speedRates.length; i++) {
            var option = document.createElement('option');
            option.textContent = speedRates[i] + "x";

            option.setAttribute('data-speed', speedRates[i]);
            rs.appendChild(option);
        }
        rs.selectedIndex = selectedIndex;
        if (!speechRate)
            setSpeechRate(speedRates[0])

    }, [])

    useEffect(() => {
        if (voices.length === 0 || voiceSelect.current === null) return
        const vs = voiceSelect.current
        var selectedIndex = vs.selectedIndex < 0 ? 0 : vs.selectedIndex;
        vs.innerHTML = '';
        for (let i = 0; i < voices.length; i++) {
            var option = document.createElement('option');
            option.textContent = voices[i].name.replace("Google ", "") + ' (' + voices[i].lang + ')';
            if (voices[i].default) {
                option.textContent += ' -- DEFAULT';
            }
            option.setAttribute('data-lang', voices[i].lang);
            option.setAttribute('data-name', voices[i].name);
            vs.appendChild(option);
        }
        vs.selectedIndex = selectedIndex;
        if (!selectedVoice)
            setSelectedVoice(voices[0])
    }, [voices, voiceSelect.current])


    // if (speechSynthesis.onvoiceschanged !== undefined) {
    //     speechSynthesis.onvoiceschanged = populateVoiceList;
    // }

    useEffect(() => {
        console.log(selectedVoice)
        // populateVoiceList(voiceSelect.current)
        if (selectedVoice) {
            // props.onSelect(selectedVoice)
            // setSelectedVoice(selectedVoice)
        }
    }, [selectedVoice])

    function selectVoice(e) {
        const voice = e.target
        console.log(voice.selectedOptions[0].getAttribute('data-name'))
        setSelectedVoice(voices.filter(v => v.name === voice.selectedOptions[0].getAttribute('data-name'))[0])
    }

    function selectRate(e) {
        const speed = e.target
        console.log(speed)
        setSpeechRate(speed.selectedOptions[0].getAttribute('data-speed'))
    }


    return (
        <div className="sticky-top">
            <div className="d-flex">
                <select className="form-control flex-grow-1" ref={voiceSelect} onChange={selectVoice}></select>
                <select className="form-control flex-shrink-1" ref={rateSelect} onChange={selectRate}></select>
            </div>
        </div>
    )
}
