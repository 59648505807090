
import styled from 'styled-components'


import {
    mainFont
} from './CssDefaultAttributes'

const SimpleInput = styled.input`
width:100%;

border: 1px solid #6D7278;
box-sizing: border-box;
border-radius: 6px;

background-color: white;
min-width: 300px;

font-family:${mainFont};
font-style: normal;
font-weight: normal;
font-size: 16px;

padding: 12px;



`


export default SimpleInput