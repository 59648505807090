const tokenManager = {

    saveAccessToken: (accessToken) => {
        localStorage.setItem('ACCESS_TOKEN', accessToken);
    },

    getAccessToken: () => {
        const accessToken = localStorage.getItem('ACCESS_TOKEN');

        return accessToken === 'null' ? null : accessToken
    },

    saveRefreshToken: (refreshToken) => {

        localStorage.setItem('REFRESH_TOKEN', refreshToken);
    },

    getRefreshToken: () => {
        const refreshToken = localStorage.getItem('REFRESH_TOKEN');

        return refreshToken === 'null' ? null : refreshToken
    },

    clearAllTokens: () => {
        tokenManager.saveAccessToken(null)
        tokenManager.saveRefreshToken(null)
    }


}

export default tokenManager